//========================================================
// MENU
//========================================================

var currentPage = $('body').attr('id');
var currentMenu = $('.menu.desktop').find('li.page');

if (currentPage) {
    $("#site-menu-site li[data-current-page='"+ currentPage +"']").addClass('current');
    $(".menu.mobile li[data-current-page='"+ currentPage +"']").addClass('current');
}
if(currentPage = 'dashboard'){
    $("#site-menu-dashboard li[data-current-page='"+ currentPage +"']").addClass('current');
}


//==========================================================================
// Main nav
//==========================================================================

var $menu = $('#site-menu-site, #site-menu-dashboard');
$menu.menuAim({
    activate: activateSubmenu,
    deactivate: deactivateSubmenu,
    enter: function(){},
    exit: function(){},
    exitMenu: function() {
        $menu.find('.trigger').removeClass('active');
        return true;
    },
    rowSelector: ".with-submenu",
    submenuSelector: "*",
    submenuDirection: "below",
    activationDelay: 250
});

function activateSubmenu(row, activeRow) {
    row = $(row);

    row.addClass('current');

    if(activeRow){
        row.find('.submenu').show();

    } else {
        row.find('.submenu').slideDown(300);
    }
}

function deactivateSubmenu(row, nextRow) {
    row = $(row);
    row.removeClass('current');

    if(nextRow){
        row.find('.submenu').hide();
    } else {
        $('.submenu').slideUp(300, function(){
            $('.trigger').removeClass('current');
        });
    }
}

//========================================================
// MOBILE MENU
//========================================================

var mobileMenuIsOpened = false;

$('.mobile-menu-icon').click(function(e) {
    e.stopPropagation();
    toggleMenu();
});

function toggleMenu() {
    if(mobileMenuIsOpened)
        mobileMenuIsOpened = false;
    else
        mobileMenuIsOpened = true;

    $('body').toggleClass('open-mobile-menu');
}

$('.mobile-site-container, .main-content, .meta-footer').click(function(e) {
    e.stopPropagation();
    if(mobileMenuIsOpened)
        toggleMenu();
});

$('.has-mobile-submenu > a').click(function(e){
    e.preventDefault();
    if($(this).parent('li').hasClass('open-submenu')){
        $(this).parent('li').removeClass('open-submenu').find('.mobile-submenu').stop(true, true).slideUp();
    } else {
        $(this).parent('li').addClass('open-submenu').find('.mobile-submenu').stop(true, true).slideDown();
    }
});

//========================================================
// SELECT
//========================================================

$('.nav .menu select').on('change', function(){
    window.location = this.value;
});

$('select').closest('.wrapper-input').addClass('select');