export function visualiseCdpContribPdf($form, $route, $itemId= '', $slug = "", $mode= '') {
    if ($form.length > 0) {
        let data = {};
        $form.serializeArray().forEach((object) => {
            data[object.name] = object.value;
        });
        data['slug'] = $slug;
        data['mode'] = $mode;
        // console.log(Routing.generate($route,{'itemId':$itemId}));
        $('.lds-dual-ring').show();
        $.ajax({
            url: Routing.generate($route,{'itemId':$itemId}),
            type: 'post',
            dataType: "JSON",
            data: data,
            async: true,
            success: function (data) {
            }
        }).statusCode({
            500: function (response, request) {
                data = JSON.parse(response.responseText);
                resetValidationError($form);
                if (data.error) {
                    let errors = JSON.parse(data.error);
                    formValidationErrors(errors, []);
                }
            },
            404: function (data) {
                //console.log(data.messge);
            },
            400: function (data) {
                resetValidationError($form);
                //$('.lds-dual-ring').hide();
            },
            200: function (data) {
                //console.log(data.errors);
                //$('.lds-dual-ring').hide();
                resetValidationError($form);
                let inputNameList = [];
                if (data.errors) {
                    let errors = JSON.parse(data.errors);
                    inputNameList = formValidationErrors(errors, inputNameList);
                    $('html, body').animate({
                        scrollTop: $(".message-error").offset().top-130
                    }, 800);
                }
                //console.log(inputNameList);
                if (inputNameList.length === 0
                    || (inputNameList.length === 1 && inputNameList.includes('contribOptIn'))) {
                    let url = Routing.generate('generate_visualise_cdp_contrib_pdf', {dossierHash: data.dossierHash}, true);
                    let windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
                    window.open(url, "cdp_contrib_pdf", windowFeatures);
                }
            }
        }).always(function() {
            $('.lds-dual-ring').hide();
        });
    }

    function resetValidationError(form) {
        let errorContainer = $form.find('.message-error');
        let errorClass = $form.find('.field-container.error');
        errorContainer.html('');
        errorClass.removeClass('error');
    }

    function formValidationErrors(errors, inputNameList = []) {
        for (const [name, message] of Object.entries(errors)) {
            if (name !== '0' && name !== 'profile' && name !== 'user' && name !== 'first' && name !== 'plainPassword') {
                inputNameList.push(name);
            }
            let $field = $('input[name$="['+name+']"]');
            if ($field && name !== 'contribOptIn') {
                let $field_container = $field.parent().parent();
                if (!$field_container.hasClass('error')) {
                    $field_container.addClass('error');
                    $field.parent().prepend( '<div class="message-error">'+message+'</div>' );
                }
            }
            if (typeof message === 'object') {
                formValidationErrors(message, inputNameList);
            }
        }
        return inputNameList;
    }
}