$('document').ready(function () {

    var list = $('#references-dossiers-list');

    var selectAll = $('#scan_check_all'),
        selectedDossiers = $("#tableRecap").find('.selected_dossier:checked');


    var Refresh = function() {
        selectedDossiers.each(function (key, value) {
            var counter = list.children().length;

            var newWidget = list.attr('data-prototype');
            newWidget = newWidget.replace(/__name__/g, counter);

            var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
            newElem.find('div').find('input').val(value.value);
            newElem.appendTo(list);
        });
    };
    Refresh();

    var add = function(reference) {

        var counter = list.children().length;

        var newWidget = list.attr('data-prototype');
        newWidget = newWidget.replace(/__name__/g, counter);

        var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
        newElem.find('div').find('input').val(reference);
        newElem.appendTo(list);
    };

    var del = function(reference) {

        $("input[value=" + reference + "]").closest('li').remove();
    };

    selectAll.change(function () {
        if ($(this).is(':checked')) {
            list.empty();
            $('.selected_dossier').prop("checked", true);
            Refresh();
        } else {
            list.empty();
            $('.selected_dossier').prop('checked', false);
        }
    });


    $('.selected_dossier').on('change', function () {
        if ($(this).is(':checked')) {
            $(this).prop("checked", true);
            add($(this).val());
        } else {
            $(this).prop('checked', false);
            del($(this).val());
        }
    });
});



