$('document').ready(function () {

    var partnerAutocomplete = $('input:text[name*=partner_autocomplete]');
    var partnerInput = $('input:text[id*=partner_autocomplete]').add($('input:hidden[name="partner_filter[partner]"]')).add($('input:hidden[name="dossier_complete_filter[partner]"]'));

    var agPartnerAutocomplete = $('input:text[name*=partner_autocomplete_ag]');
    var agPartnerInput = $('input:text[id*=partner_autocomplete_ag]').add($('input:hidden[name="partner_filter[partner]"]')).add($('input:hidden[name="dossier_complete_filter[partner]"]'))
    var pathname = window.location.pathname;
    var route = (pathname === '/synthese') ? 'api_partner_find_ag' : 'api_partner_find_all';

    partnerAutocomplete.autocomplete({
        source: function (request, response) {
            $.ajax(Routing.generate(route, {
                query: partnerAutocomplete.val()
            })).done(function (data) {
                response($.map(data, function (partner) {
                    return {
                        value: partner.companyName,
                        id: partner.id
                    }
                }))
            })
        },

        minLength: 2,
        select: function (e, data) {
            partnerInput.val(data.item.id);
        }
    });

    agPartnerAutocomplete.autocomplete({
        source: function (request, response) {
            $.ajax(Routing.generate('api_partner_find_ag', {
                query: partnerAutocomplete.val()
            })).done(function (data) {
                response($.map(data, function (partner) {
                    return {
                        value: partner.companyName,
                        id: partner.id
                    }
                }))
            })
        },

        minLength: 2,
        select: function (e, data) {
            agPartnerInput.val(data.item.id);
        }
    });
});



