import MicroModal from "micromodal";

export function showComingSoonPopup() {
    if ($("#certify-modal").length > 0) {
        MicroModal.show("coming-soon-popup");
    }
}

export function showCertifyPopUp() {
    if ($("#certify-modal").length > 0) {
        MicroModal.show("certify-modal");
    }
}

export function showIframeValidatePopUp() {
    if ($("#iframe-validate-modal").length > 0) {
        MicroModal.show("iframe-validate-modal");
    }
}

export function showInstructionPopup() {
    if ($("#ask-for-instruction-popup").length) {
        if ($('#ask-for-instruction-popup').data('render-popup') == true) {
            MicroModal.show("ask-for-instruction-popup");

            $('#no-reinstruction').on('click', function (e) {
                e.preventDefault();
                MicroModal.close("ask-for-instruction-popup");
            });
        }
    }
}

export function showDossiercoordonneesPopup() {
    if ($("#dossier-coordonnees-popup").length) {
        if ($('#dossier-coordonnees-popup').data('render-popup') == true) {
            MicroModal.show("dossier-coordonnees-popup");

            $('#no-action').on('click', function (e) {
                e.preventDefault();
                MicroModal.close("dossier-coordonnees-popup");
            });
        }
    }
}

// if ($("#ask-for-instruction-popup").length) {
//     $(".modal__overlay").off("click");
// }

$("#modal-modify-btn").click(function () {
    MicroModal.close("modal-1");
});

// ASK FOR DOSSIER REINSTRUCTION POPUP FOR PERSONAL SPACE
if ($("#ask-for-instruction-popup").length) {
    showInstructionPopup();
}

if ($("#dossier-coordonnees-popup").length) {
    showDossiercoordonneesPopup();
}

// INCOMPLETE EMAIL VIEWER FOR PERSONAL SPACE
if ($("#open_incomplete_modal").length) {
    // $(".modal__overlay").off("click");

    $("#open_incomplete_modal").on('click', function (e) {
        e.preventDefault();
        if ($("#incomplete-email-modal").length) {

            MicroModal.show("incomplete-email-modal");

            $('#close-email-modal').on('click', function (e) {
                e.preventDefault();
                MicroModal.close("incomplete-email-modal");
            });
        }
    });
}

if ($("#incomplete-popup").length) {
    // $(".modal__overlay").off("click");

    MicroModal.show("incomplete-popup");

    $('#incomplete-popup').on('click', function (e) {
        e.preventDefault();
        MicroModal.close("incomplete-popup");
    });
}

if($("#relance-artisan-modal").length) {
    MicroModal.show("relance-artisan-modal");
}


