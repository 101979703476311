$('document').ready(function () {
    if(window.location.pathname.match('pro-reno-globale') == null) {
        var professionnelsContainer = $('.professionnels-container');
        var professionnelsCountBase = professionnelsCount = professionnelsContainer.find('input[name*=proCourriel]').length;

        var addProBtn = $('<br><br><a href="#" style="float:right;" class="add-professionnels professionnels-action"><i class="fa fa-plus-circle add-professionnels professionnels-action s14"  title="Ajouter un professionnel"></i> Ajouter un professionnel</a>');
        var removeProBtn = $('<br><br><a href="#" style="float:right;" class="remove-professionnels professionnels-action"><i class="fa fa-times-circle remove-professionnels professionnels-action s14"  title="Supprimer un professionnel"></i> Supprimer un professionnel</a>');

        professionnelsContainer.data('professionnelsCount', professionnelsContainer.find('input[name*=proCourriel]').length);
        var proCourriel = professionnelsContainer.find('input[name*=proCourriel]')

        function isAddProfessionnelsBtn() {
            return proCourriel.length > 0;
        }

        if (isAddProfessionnelsBtn()) {
            proCourriel.last().after(addProBtn);
            // $('#ash_administration_professionnels_0_proSousTraitant_0').prop("checked", true);
        } else {
            if (professionnelsCount === 1) {
                professionnelsContainer.find('input[name*=proSousTraitantFirstName]').last().after(addProBtn);
            } else {
                professionnelsContainer.find('label[for*=proSousTraitant]').last().after(addProBtn);
            }
        }

        $(document).on('click', '.add-professionnels', function () {
            appendProfessionnelsInputs();
            professionnelsCount++;

            return false;
        });

        $(document).on('click', '.remove-professionnels', function (e) {
            e.preventDefault();

            $(this).closest('.professionnels-container').remove();
            professionnelsCount--;
            professionnelsContainer.data('professionnelsCount', professionnelsCount);

            if (isAddProfessionnelsBtn()) {
                if (professionnelsCount === professionnelsCountBase) {
                    professionnelsContainer.find('input[name*=proCourriel]').last().after(addProBtn);
                } else {
                    $("#ash_administration_professionnels_" + professionnelsCount).find('input[name*=proCourriel]').last().after(removeProBtn).after(addProBtn);
                }
            } else {
                if (professionnelsCount === professionnelsCountBase) {
                    professionnelsContainer.find('input[name*=proSousTraitantFirstName]').last().after(addProBtn);
                } else {
                    $("#ash_administration_professionnels_" + professionnelsCount).find('label[for*=proSousTraitant]').last().after(removeProBtn).after(addProBtn);
                }
            }

        });

        function appendProfessionnelsInputs() {
            var prototype = professionnelsContainer.data('prototype');

            var inputCount = professionnelsContainer.data('professionnelsCount');
            inputCount = inputCount + 1;

            prototype = prototype.replace(/__name__/g, inputCount);

            var form = $(prototype);

            professionnelsContainer.data('professionnelsCount', inputCount);

            form.addClass('row');
            form.addClass('professionnels-container');
            if($("div[id^='proSousTraitant_']").is(':hidden')){
                form.find('input[name*=proCourriel]').last().after(removeProBtn).after(addProBtn);
            }else{
                form.find('label[for*=proSousTraitant]').last().after(removeProBtn).after(addProBtn);
            }

            if (isAddProfessionnelsBtn()) {
                form.find('input[name*=proCourriel]').last().after(removeProBtn).after(addProBtn);
            } else {
                form.find('label[for*=proSousTraitant]').last().after(removeProBtn).after(addProBtn);
            }

            form.find('.field-container').removeClass('padding-left-7');

            professionnelsContainer.append(form)

            var newItem = document.createElement("DIV");
            var textnode = document.createTextNode("IDENTITÉ DU PROFESSIONNEL " + inputCount);
            var list = document.getElementById("ash_administration_professionnels_" + inputCount);

            newItem.setAttribute('class', 'ash_section_title');
            newItem.appendChild(textnode);
            list.insertBefore(newItem, list.childNodes[0]);
        }
    }
});
