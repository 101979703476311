const MicroModal = require('micromodal/dist/micromodal.min');

/**
 *
 * @param workId
 * @returns {*}
 */
export function simulationAlreadyInCart(workId, targetUserId) {
    var ajax = $.ajax({
        url: Routing.generate('core_simulation_already_exists', {
            eligible_id: workId,
            target_user_id: targetUserId
        }),
        dataType: 'json',
        async: false
    });

    return ajax.responseJSON;
}

/**
 *
 * @param editUrl
 * @param workTitle
 */
export function showExistingSimulationInCartPopUp(editUrl, workTitle) {
    let popup = $('<div class="modal micromodal-slide container" id="existing_simulation_modal" aria-hidden="true">\n' +
        '    <div class="modal__overlay" tabindex="-1" data-micromodal-close>\n' +
        '        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="coming-soon-title">\n' +
        '            <header class="modal__header">\n' +
        '                <h2 class="modal__title category-title orange">\n' +
        '                    Erreur panier\n' +
        '                </h2>\n' +
        '                <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>\n' +
        '            </header>\n' +
        '            <main class="modal__content">\n' +
        '                <p>Votre panier contient déjà des travaux <b>' + workTitle + '</b>, cliquez sur le lien ci-dessous pour ajuster votre simulation.</p>\n' +
        '<div class="btn-container text-center"><a href="' + editUrl + '" class="btn text-center">Je modifie mes travaux</a></div>' +
        '            </main>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>');

    $('body').append(popup);

    MicroModal.show("existing_simulation_modal", {});
}

export function getSubdomain() {
    let hostName = window.location.hostname;

    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostName);
    return hostName.replace(urlParts[0], '').slice(0, -1);
}