$(document).ready(function () {
    var triggers = $('.faq-trigger');
    var targets = $('.faq-content');
    var targetId;
    triggers.click(function () {
        targetId = '#' + $(this).data('target');

        resetDisplay(targetId);

        $(this).parent()
            .toggleClass('active');

        $(targetId)
            .toggleClass('hidden');
    });


    function resetDisplay(currentId) {
        targets.not('.hidden').not(currentId)
            .addClass('hidden');

        targets.not(currentId).parent()
            .removeClass('active')
    }
});