$('document').ready(function () {
    var precarityTrigger = $('.preca-trigger'),
        deptInput = $('select[name*=DEPT]').add("#devis_lead_audit_departement"),
        zipcodeInput = $('input[name*=zipcode]'),
        quotationDateInput = $('input[name*=quotation_date]'),
        revenuFoyerInput = $('select[name*=revenu_foyer]').add('select[name*=PRECA_REVENU_MENAGE]').add('#leadaudit_precaLevel').add("#devis_lead_audit_precaLevel"),
        nbHabFoyerInput = $('input[name*=nb_hab_foyer]').add('input[name*=PRECA_NB_HABITANT]').add('#leadaudit_nbMenage').add("#devis_lead_audit_nbMenage"),
        existingRevenuValue = revenuFoyerInput.val();

    var today = $.datepicker.formatDate('yy-mm-dd', new Date());

    var values = [];

    // Fetch revenues on nbHabFoyer value changes if other required values arent missing
    precarityTrigger.on('change', function () {
        // When department input is not on the current page (basically for all profile edition), we guess it by zipcode input
        if (deptInput.val() === undefined) {
            dept = zipcodeInput.val().substring(0, 2).replace('2A', '20').replace('2B', '20');
        } else {
            dept = deptInput.val();

            if (dept > 20) {
                dept = --dept;
            }
        }

        // When quotation date input is not on the current page, we set it to the current date
        if (quotationDateInput.val() === undefined || quotationDateInput.val() == "") {
            quotationDate = today;
        } else {
            quotationDate = quotationDateInput.val();
        }

        values = [nbHabFoyerInput.val(), dept, quotationDate];

        if (false === hasMissingValues()) {
            getRevenus();
        }

    }).change();

    /**
     * Async call to fetch revenues
     */
    function getRevenus() {
        $.ajax({
            url: Routing.generate('api_get_foyer_revenu'),
            datatype: 'json',
            data: {
                nb_hab: nbHabFoyerInput.val(),
                dept: dept,
                quotation_date: quotationDate
            },
            success: function (response) {
                revenuFoyerInput.empty();
                // Replace select values with new ones..
                $.each(response, function (key, label) {
                    revenuFoyerInput.append($("<option/>", {
                        value: key,
                        text: label
                    }));
                });

                // Preset existing value
                revenuFoyerInput.val(existingRevenuValue);

                if (revenuFoyerInput.find('option:selected').length == 0) {
                    revenuFoyerInput.find('option:first').attr('selected', 'selected');
                }
            },
            error: function (error) {
                //@todo handle error..
            }
        });
    }

    /**
     * Callback to filter missing values required to fetch revenues
     *
     * @param element
     * @returns {boolean}
     */
    function filterMissingValues(element) {
        return (element == typeof null || element < 1 || element == typeof undefined || element == "");
    }

    /**
     * Filter missing values
     *
     * @returns {boolean}
     */
    function hasMissingValues() {
        return values.some(filterMissingValues);
    }
});


