$('document').ready(function () {
    var day = $('[data-date-type="day"]');
    var month = $('[data-date-type="month"]');
    var year = $('[data-date-type="year"]');

    day.on('input', function (e) {
        let closestMonthInput = $(this).closest('.wrapper-input').find('[data-date-type="month"]');

        $(this).val($(this).val().replace(/[^0-9\.]/g, ''));

        if ((e.which != 46 || $(this).val().indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }

        if ($(this).val().length == 2) {
            closestMonthInput.focus();
        }
    });

    month.on('input', function (e) {
        let closestYearInput = $(this).closest('.wrapper-input').find('[data-date-type="year"]');

        $(this).val($(this).val().replace(/[^0-9\.]/g, ''));

        if ((e.which != 46 || $(this).val().indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }

        if ($(this).val().length == 2) {
            closestYearInput.focus();
        }
    });

    year.on('input', function (e) {
        $(this).val($(this).val().replace(/[^0-9\.]/g, ''));

        if ((e.which != 46 || $(this).val().indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    });

});