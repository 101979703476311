(function( $ ){
    $.fn.dialogMp = function(options) {
        var buttonContainer = $('');

        // BUTTONS GENERATION
        if ((typeof options == "object") && (options != null)) {
            if(options.buttons && options.buttons != null) {
                var buttonContainer = $('<div class="btn-container">');

                $.each(options.buttons, function(name, func) {
                    var button = $('<button>').text(name).addClass('btn').click(func);
                    buttonContainer.append(button);
                });
            }
        }

        var popinContainer = $('<div id="popin-container" class="popin-container">')
            .html(this.html());

        popinContainer.append(buttonContainer);

        var popin = $(
            '<div class="marypopin-mask" id="autopopin">' +
            '   <div id="popin-solidaire" class="">' +
            // '       <div class="close-popin marypopin-close"></div>' +
            '</div>' +
            '</div>'
        ).find('#popin-solidaire').append(popinContainer);

        $('body').append(popin);

        $('#popin-solidaire').marypopin({
            afterClose: function() {
                $('#autopopin').remove();
                $('#popin-solidaire').remove();
            }
        });
        $('#popin-solidaire').marypopin('open');
    };
})( jQuery );