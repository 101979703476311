import {showCertifyPopUp} from "./popups";

$('document').ready(function () {
    var checkbox = $('#i_certify_checkbox');
    var signBtn = $('.electro-sign-btn');
    var voidBtn = $('#void-envelope');
    var refuseBtn = $('#refuse-envelope');

    var digitalDossierPellet = $('#digital_dossier_pellet');
    var signatureAnchor = $('#signatures');

    if (signBtn.length) {
        signBtn.on('click', function (e) {
            if (!checkbox.is(':checked')) {
                e.preventDefault();
                showCertifyPopUp();
            }
        });
    }

    if (signatureAnchor.length) {
        if (digitalDossierPellet.length) {
            digitalDossierPellet.on('click', function () {
                $("html, body").animate({scrollTop: signatureAnchor.offset().top}, 'slow');
            });
        }
    }

    if (voidBtn.length) {
        voidBtn.on('click', function (e) {
            e.preventDefault();
            showConfirmBox($(this).attr('href'), "Etes-vous sûr de vouloir annuler la demande de signature ?");
        });
    }

    if (refuseBtn.length) {
        refuseBtn.on('click', function (e) {
            e.preventDefault();
            showConfirmBox($(this).attr('href'), "Etes-vous sûr de vouloir refuser la demande de signature ?");
        });
    }

    function showConfirmBox(href, content) {
        $.confirm({
            title: "Confirmation",
            content: content,
            type: 'red',
            buttons: {
                ok: {
                    text: "Confirmer",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        location.href = href;
                    }
                },
                cancel: {
                    text: "Annuler",
                    action: function () {
                        return;
                    }
                }
            }
        });
    }
});