var activeElement = 0;

var accordionMenu = $('.accordion-menu');
var accordionMenuLinks = $('.accordion-menu .accordion-item-title');
var accordingMenuList = $('.accordion-menu ul');

accordionMenuLinks.each(function (index, element) {
    if ($(element).hasClass('open')) {
        activeElement = index;
    }
});

accordingMenuList.each(function (index, element) {
    if ($(element).hasClass('open')) {
        activeElement = index;
    }
});

if (accordionMenu.hasClass('click')) {
    accordionMenu.accordion({
        heightStyle: 'content',
        event: 'click',
        active: activeElement,
        beforeActivate: function (event, ui) {
            return false;
        }
    });

    accordionMenu.find('a').click(function (event) {
        window.location.href = $(this).attr("href");
    });

} else {
    accordionMenu.accordion({
        heightStyle: 'content',
        event: 'mouseover',
        active: activeElement,
    });
}