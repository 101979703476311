$('document').ready(function () {
    var rgeFileContainer = $('.rge-file-container');
    var count = 0;
    var addBtn = $('<a href="#"><i class="fa fa-plus add-rge-file-household"></i></a>');

    rgeFileContainer.data('count', rgeFileContainer.find('input[name*=validityEndDate]').length);
    rgeFileContainer.find('input[name*=validityEndDate]').last().after(addBtn);

    $('.add-rge-file-household').on('click', function () {
        count++;

        if (count < 11) {
            appendHouseHoldInputs();
        }

        return false;
    });

    function appendHouseHoldInputs() {
        var prototype = rgeFileContainer.data('prototype');

        var inputCount = rgeFileContainer.data('count');

        inputCount = inputCount + 1;

        prototype = prototype.replace(/__name__/g, inputCount);

        var form = $(prototype);

        rgeFileContainer.data('count', inputCount);

        form.find('label[for*=filename]').text('RGE n° ' + inputCount);
        form.addClass('big');

        form.find('input[name*=validityEndDate]').last().after(addBtn);

        rgeFileContainer.append(form);

        form.find('input[name*=validityEndDate]').datepicker({
            firstDay: 1,
            closeText: 'Fermer',
            prevText: 'Précédent',
            nextText: 'Suivant',
            currentText: 'Aujourd\'hui',
            monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            monthNamesShort: ['Jan.', 'Fév.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
            dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
            weekHeader: 'Sem.',
            dateFormat: 'dd / mm / yy',
        });

        form.find('input[name*=validityStartDate]').datepicker({
            firstDay: 1,
            closeText: 'Fermer',
            prevText: 'Précédent',
            nextText: 'Suivant',
            currentText: 'Aujourd\'hui',
            monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            monthNamesShort: ['Jan.', 'Fév.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
            dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
            weekHeader: 'Sem.',
            dateFormat: 'dd / mm / yy',
        });
    }
});
