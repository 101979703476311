import {getSubdomain} from "../../Utility/url_helpers";

const WWW_SUBDOMAIN = 'www';
const ENTREPRISE_SUBDOMAIN = 'entreprises';
const PRO_SUBDOMAIN = 'pro';
const COPRO_SUBDOMAIN = 'copropriete';
const IFRAME_SUBDOMAIN = 'iframe';

$('document').ready(function () {

    /**
     * WWW SIMULATOR FIELDS CONDITIONAL DISPLAY
     */
    if ($.inArray(getSubdomain(), [WWW_SUBDOMAIN, ENTREPRISE_SUBDOMAIN, COPRO_SUBDOMAIN, IFRAME_SUBDOMAIN]) > -1) {
        var hasRgeArtisanInput = $('input[name*=has_rge_artisan]');
        var knowEstimatedInvoiceDateInput = $('input[name*=know_estimated_invoice_date]');
        var wishToBeContactedByArtisanWrapper = $('.wish-to-be-contacted-wrapper');
        var estimatedInvoiceDateWrapper = $('.estimated-invoice-date-wrapper');

        /**
         * CONDITIONAL DISPLAY ON PAGE LOAD ACCORDING TO PRESET VALUES
         */
        if (hasRgeArtisanInput.is(':checked') && hasRgeArtisanInput.prop('checked') == 1) {
            hideWrapper(wishToBeContactedByArtisanWrapper);
        } else {
            showWrapper(wishToBeContactedByArtisanWrapper);
        }

        /**
         * CONDITIONAL DISPLAY ON PAGE LOAD ACCORDING TO PRESET VALUES
         */
        if (knowEstimatedInvoiceDateInput.is(':checked') && knowEstimatedInvoiceDateInput.prop('checked') == 1) {
            showWrapper(estimatedInvoiceDateWrapper);
        } else {
            hideWrapper(estimatedInvoiceDateWrapper);
        }

        hasRgeArtisanInput.on('change', function (e) {
            let val = $(this).val();

            if (val == 0) {
                showWrapper(wishToBeContactedByArtisanWrapper);
            } else {
                hideWrapper(wishToBeContactedByArtisanWrapper);
            }
        });

        knowEstimatedInvoiceDateInput.on('change', function (e) {
            let val = $(this).val();

            if (val == 1) {
                showWrapper(estimatedInvoiceDateWrapper);
            } else {
                hideWrapper(estimatedInvoiceDateWrapper);
            }
        });
    }

    /**
     * ENTREPRISE AND COPRO SIMULATOR FIELDS CONDITIONAL DISPLAY
     */
    if (ENTREPRISE_SUBDOMAIN === getSubdomain() || COPRO_SUBDOMAIN === getSubdomain()) {
        //Implements logic
    }

    /**
     * PRO SIMULATOR FIELDS CONDITIONAL DISPLAY
     */
    if (PRO_SUBDOMAIN === getSubdomain()) {
        //Implements logic
    }

    /**
     *
     * @param element
     */
    function showWrapper(element) {
        element.show();
    }

    /**
     *
     * @param element
     */
    function hideWrapper(element) {
        element.hide();
    }
});