$(document).ready(function() {
    var form = $('form');
    var trigger = $('button[type*=submit]');
    var containers = $('.addressField');

    trigger.click(function(e) {
        e.preventDefault();
        $.each(containers, function() {
            // if($(this).find('.field-container.hidden'))
            //     $(this).remove();
        });

        form.submit();
    });

    $('label[for*=different_address_0]').click(function() {
        if(!containers.hasClass('hidden'))
            containers.addClass('hidden')
    });

    $('label[for*=different_address_1]').click(function() {
        if(containers.hasClass('hidden'))
            containers.removeClass('hidden')
    });

    $('input[name*=different_address]').each(function() {
        if($(this).val() == 0 && $(this).prop('checked')) {
            if(!containers.hasClass('hidden'))
                containers.addClass('hidden')
        }
    })
});