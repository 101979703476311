//========================================================
// STICKY BAR
//========================================================
var stickyNav = undefined;

stickyNav = {
    capability: 0,
    topPos: undefined,
    navElement: $('.sticky-bar'),
    listeners: [],

    init: function(){
        var self = this;

        if(window.pageYOffset != undefined)
            self.capability = 1;
        else if(document.body && document.body.scrollTop != undefined)
            self.capability = 2;

        self.scrollCheck();

        $(window).scroll(function(){
            var isVisible = self.scrollCheck();
            self.notify(isVisible);
        });
    },
    scrollCheck: function(){
        var self = this;

        switch(self.capability){
            case 1 :
                self.topPos = window.pageYOffset;
                break;
            case 2 :
                self.topPos = document.body.scrollTop;
                break;
            default :
                self.topPos = 0;
                break;
        }

        var isVisible = self.topPos > 200
        if(isVisible)
            self.navElement.addClass('show');
        else
            self.navElement.removeClass('show');
        return isVisible && self.navElement.length != 0;
    },
    addListener: function(callback) {
        var self = this;
        self.listeners.push(callback);
    },
    notify: function(isStickyBarVisible) {
        var self = this;
        $.each(self.listeners, function(index, listener) {
            listener(isStickyBarVisible);
        })
    }
};

stickyNav.init();

stickyNav.addListener(function(isVisible) {
    if(isVisible) {
        $('footer#footer').addClass('has-sticky');
    } else {
        $('footer#footer').removeClass('has-sticky');
    }
});

// on souhaite avoir un footer toujours en bas de page
$('footer#footer').css({position: 'absolute', 'bottom': 0, 'width': '100%'});
var padding = $('footer#footer').height() + $('.sticky-bar:visible').height();
$('html').css('height', '100%');
$('body').css({minHeight: '100%', position: 'relative', paddingBottom: padding + 'px'});