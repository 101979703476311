$('document').ready(function () {
    var taxHouseHoldContainer = $('.tax-household-container');
    var houseHoldCount = 1;

    var addBtn = $('<a href="#"><i class="fa fa-plus add-tax-household tax-household-action fs-13"></i></a>');
    var removeBtn = $('<a href="#"><i class="fa fa-times tax-household-action remove-tax-household fs-14"></i></a>');

    taxHouseHoldContainer.data('houseHoldCount', taxHouseHoldContainer.find('input[name*=filerLastName]').length);
    taxHouseHoldContainer.find('input[name*=filerFirstName]').last().after(addBtn);

    $(document).on('click', '.add-tax-household', function () {
        if (houseHoldCount < 5) {
            appendHouseHoldInputs();
            houseHoldCount++;
        }

        return false;
    });

    $(document).on('click', '.remove-tax-household', function (e) {
        e.preventDefault();

        $(this).closest('.household-container').remove();

        houseHoldCount = houseHoldCount - 1;
        taxHouseHoldContainer.data('houseHoldCount', houseHoldCount);

        if (houseHoldCount == 1) {
            taxHouseHoldContainer.find('input[name*=filerFirstName]').last().after(addBtn);
        } else {
            $("#ash_administration_taxHouseHold_" + houseHoldCount).find('input[name*=filerFirstName]').last().after(addBtn).after(removeBtn);
        }

    });


    function appendHouseHoldInputs() {
        var prototype = taxHouseHoldContainer.data('prototype');

        var inputCount = taxHouseHoldContainer.data('houseHoldCount');

        inputCount = inputCount + 1;

        prototype = prototype.replace(/__name__/g, inputCount);

        var form = $(prototype);

        taxHouseHoldContainer.data('houseHoldCount', inputCount);

        form.find('label[for*=filerLastName]').text('Nom du déclarant principal du ' + inputCount + 'ème foyer');
        form.find('label[for*=filerFirstName]').text('Prénom du déclarant principal du ' + inputCount + 'ème foyer');
        form.addClass('row');
        form.addClass('household-container');

        form.find('.field-container').removeClass('padding-left-7');
        form.find('input[name*=filerFirstName]').last().after(addBtn).after(removeBtn);

        taxHouseHoldContainer.append(form)
    }
});
