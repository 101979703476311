$('document').ready(function () {
    if (window.location.pathname.match('audit-reno-globale') != null) {
        var scenarioContainer = $('.scenario-container');
        $('#ash_administration_scenarios').closest('.col-lg-6').remove();
        var addNewBtn = $('<div class="action-container" style="text-align: right">' +
            '<a href="#" style="display: inline-block; margin: 30px 0 20px;" class="add-scenario add-action"><i class="fa fa-plus-circle add-professionnels professionnels-action s14" title="Ajouter un professionnel"></i> Ajouter un scénario</a>' +
            '</div>');
        var deleteBtn = $('<div class="action-container" style="text-align: right">' +
            '<a href="#" style="display: inline-block;" class="remove-scenario delete-action"><i class="fa fa-times-circle remove-professionnels professionnels-action s14"  title="Supprimer un professionnel"></i> Supprimer un scénario</a>' +
            '</div>');

        var counter = scenarioContainer.data('widget-counter') | scenarioContainer.children().length;
        var addActionCounter = 0;
        if (!counter) {
            counter = scenarioContainer.children().length;
        }
        var labelCounter = counter + 2;// should start with 2there are already two (ex: SCENARIO CHOISI)

        scenarioContainer.after(addNewBtn);

        if ($('[name$="[AUD_SCENARIO_MULTISTEP]"]:checked').val() == 1) {
            $('[name$="[AUD_CAN_FIRST_STEP_ACHIEVE_CLASS_E]"]').parents('.col-md-12').removeClass('hidden');
            $('[name$="[AUD_IS_F_G_CLASS_INTERMEDIATE_STEP_FOR_REACH_CLASS_C]"]').parents('.col-md-12').removeClass('hidden');
            $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_B]"]').parents('.col-md-12').removeClass('hidden');
            $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_BBC]"]').parents('.col-md-12').removeClass('hidden');
        } else {
            $('[name$="[AUD_CAN_FIRST_STEP_ACHIEVE_CLASS_E]"]').parents('.col-md-12').addClass('hidden');
            $('[name$="[AUD_IS_F_G_CLASS_INTERMEDIATE_STEP_FOR_REACH_CLASS_C]"]').parents('.col-md-12').addClass('hidden');
            $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_B]"]').parents('.col-md-12').addClass('hidden');
            $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_BBC]"]').parents('.col-md-12').addClass('hidden');
        }
        $('[name$="[AUD_SCENARIO_MULTISTEP]"]').on('change', function(e) {
            if ($(this).val() == 1) {
                $('[name$="[AUD_CAN_FIRST_STEP_ACHIEVE_CLASS_E]"]').parents('.col-md-12').removeClass('hidden');
                $('[name$="[AUD_IS_F_G_CLASS_INTERMEDIATE_STEP_FOR_REACH_CLASS_C]"]').parents('.col-md-12').removeClass('hidden');
                $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_B]"]').parents('.col-md-12').removeClass('hidden');
                $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_BBC]"]').parents('.col-md-12').removeClass('hidden');
            } else {
                $('[name$="[AUD_CAN_FIRST_STEP_ACHIEVE_CLASS_E]"]').parents('.col-md-12').addClass('hidden');
                $('[name$="[AUD_IS_F_G_CLASS_INTERMEDIATE_STEP_FOR_REACH_CLASS_C]"]').parents('.col-md-12').addClass('hidden');
                $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_B]"]').parents('.col-md-12').addClass('hidden');
                $('[name$="[AUD_FINAL_STEP_ALLOW_REACH_STEP_BBC]"]').parents('.col-md-12').addClass('hidden');
            }
        })

        $(document).on('click', '.add-scenario', function (e) {
            e.preventDefault();
            var newWidget = scenarioContainer.attr('data-prototype');
            newWidget = newWidget.replace(/__name__/g, counter);
            scenarioContainer.data('widget-counter', counter);
            var widgetElem = $(newWidget);
            widgetElem.addClass('row scenario');

            widgetElem.find('.field-container').removeClass('padding-left-7');
            scenarioContainer.append(widgetElem)

            if (widgetElem.find('[name$="[hasMultipleStep]"]:checked').val() == 1) {
                widgetElem.find('[name$="[canFirstStepAchieveClassE]"]').parents('.col-lg-12').removeClass('hidden');
                widgetElem.find('[name$="[isFGClassIntermediateStepForReachClassC]"]').parents('.col-lg-12').removeClass('hidden');
                widgetElem.find('[name$="[finalStepAllowReachStepB]"]').parents('.col-lg-12').removeClass('hidden');
            } else {
                widgetElem.find('[name$="[canFirstStepAchieveClassE]"]').parents('.col-lg-12').addClass('hidden');
                widgetElem.find('[name$="[isFGClassIntermediateStepForReachClassC]"]').parents('.col-lg-12').addClass('hidden');
                widgetElem.find('[name$="[finalStepAllowReachStepB]"]').parents('.col-lg-12').addClass('hidden');
            }

            widgetElem.find('[name$="[hasMultipleStep]"]').on('change', function (event) {
                if ($(this).val() == 1) {
                    widgetElem.find('[name$="[canFirstStepAchieveClassE]"]').parents('.col-lg-12').removeClass('hidden');
                    widgetElem.find('[name$="[isFGClassIntermediateStepForReachClassC]"]').parents('.col-lg-12').removeClass('hidden');
                    widgetElem.find('[name$="[finalStepAllowReachStepB]"]').parents('.col-lg-12').removeClass('hidden');
                } else {
                    widgetElem.find('[name$="[canFirstStepAchieveClassE]"]').parents('.col-lg-12').addClass('hidden');
                    widgetElem.find('[name$="[isFGClassIntermediateStepForReachClassC]"]').parents('.col-lg-12').addClass('hidden');
                    widgetElem.find('[name$="[finalStepAllowReachStepB]"]').parents('.col-lg-12').addClass('hidden');
                }
            });

            var newItem = document.createElement("div");
            var textnode = document.createTextNode("SCENARIO " + labelCounter);

            newItem.setAttribute('class', 'ash_section_title');
            newItem.appendChild(textnode);
            var list = document.getElementById("ash_administration_scenarios_" + counter);
            list.insertBefore(newItem, list.childNodes[0]);
            scenarioContainer.after(deleteBtn).after(addNewBtn);

            counter++;
            addActionCounter++;
            labelCounter++;
        });

        // by default 2 scenarios (SCENARIO CHOISI and SCENARIO 2) should be displayed
        // if the scenario table is empty then trigger to add one
        if (counter == 0) { // only scenario choisi is there
            $('.add-scenario').trigger('click');
        }

        $(document).on('click', '.remove-scenario', function (e) {
            e.preventDefault();
            counter--;
            addActionCounter--;
            labelCounter--;
            $("#ash_administration_scenarios_"+counter).remove();
            $( ".add-scenario" ).trigger( "focus" );
            scenarioContainer.data('widget-counter', counter);
            if (addActionCounter == 0) {
                deleteBtn.remove();
            }
        });

        $(document).on('click', '.btn-delete-scenario', function (e) {
            e.preventDefault();
            var that = $(this);
            var href = $(this).attr('href');
            var popupDiv =  $('<div>' +
                '<h1 class="category-title" style="margin-top: -20px;">Suppression d’une scénario</h1>' +
                '<div class="content">' +
                '<p class="fs-16 pull-left">Êtes-vous sûr de vouloir supprimer cette Scénario ?</p>' +
                '<p class="fs-14 pull-left">Attention : En cliquant sur <b>OUI</b>, la sénario sera définitivement supprimé. Sinon, cliquez sur <b>NON</b>.</p>' +
                '</div>' +
                '<br><div class="btn-container">' +
                '<div class="marypopin-close btn blue" style="width: 50%;">NON</div>' +
                '<a class="btn remove-confirmed" style="width: 45%;">OUI</a>' +
                '</div>' +
                '</div>');
            $(popupDiv).dialogMp();
            $('.remove-confirmed').on('click', function (e) {
                e.preventDefault();
                var thisScenario = that.closest('.scenario');
                var loader = that.siblings('.ajax-loader');
                that.hide();
                loader.show();
                // thisScenario.css({'color': 'red'});
                $('#popin-solidaire').marypopin('close');
                window.location.href = href;
            })
        })
    }
});
