import * as LoadingOverlay from 'gasparesganga-jquery-loading-overlay';
import MicroModal from "micromodal";
import {showCertifyPopUp, showInstructionPopup} from "./popups";

var slug = window.location.href.split('/').pop();

var uploadTrigger,
    actionList,
    fileInput,
    dossierReference,
    fileCategoryId,
    uploadForm,
    fileTd,
    downloadRoute,
    viewRoute,
    removeRoute,
    refuseRoute,
    acceptRoute,
    errorsContainer,
    link;

var fileItemList, createdAtList, acceptedAtList, fileOwnerList;

var parentTr;

var dossier_status = $('input:hidden[name*=dossier_status]').val();

$(document).ready(function () {
    $(document).on('click', ".removeDocument, .viewer-remove-doc", function (e) {
        var href = $(this).attr('href');
        var that =  $(this);
        var popupDiv =  $('<div>' +
            '<h1 class="category-title" style="margin-top: -20px;">Suppression d’une pièce du dossier</h1>' +
            '<div class="content">' +
            '<p class="fs-16 pull-left">Êtes-vous sûr de vouloir supprimer cette pièce complémentaire ?</p>' +
            '<p class="fs-14 pull-left">Attention : En cliquant sur <b>OUI</b>, le document sera définitivement supprimé. Sinon, cliquez sur <b>NON</b>.</p>' +
            '</div>' +
            '<br><div class="btn-container">' +
            '<div class="marypopin-close btn blue" style="width: 50%;">NON</div>' +
            '<a href="'+href+'" class="btn remove-confirmed" style="width: 45%;">OUI</a>' +
            '</div>' +
            '</div>');
        $(popupDiv).dialogMp();
        event.preventDefault();
        $('.remove-confirmed').on('click', function () {
            $('#marypopin-mask').hide();
            if ($('#document-viewer').length > 0) {
                displayLoadingOverlay($('#document-viewer object'));
            }
            if ($('.dossier-file-table').length > 0) {
                displayLoadingOverlay(that.closest("td").closest('tr'));
            }
        })
        return false;
    });

    $('.upload-trigger').on('click', function (e) {
        uploadTrigger = $(this);

        // build selectors according to current <td>
        buildCurrentSelectors(uploadTrigger);

        // trigger click event to display file explorer popup
        fileInput.click();
        fileInput.off();

        // show submit button on file selection
        fileInput.on('change', function (e) {
            uploadForm.submit();
            $(this).val("");
        });

        uploadForm.submit(function (e) {
            // Prevent default form behaviour
            e.preventDefault();

            // Remove previous errors from dom
            refreshErrors();

            // Display loading overlay on form submission
            displayLoadingOverlay(parentTr);

            $.ajax({
                url: Routing.generate('api_dossier_file_upload', {
                    'file_category_id': fileCategoryId,
                    'reference': dossierReference
                }),
                type: 'post',
                data: new FormData(uploadForm[0]),
                cache: false,
                contentType: false,
                processData: false
            }).statusCode({
                500: function (data) {
                    hideLoadingOverlay(parentTr);
                },
                400: function (data) {
                    // Show errors in table dedicated column..
                    appendErrors(data.responseJSON.errors);

                    // Hide loading overlay..
                    hideLoadingOverlay(parentTr);
                },
                200: function (response) {
                    // Update dom by adding new file item to file list..
                    appendFileItem(response.data);

                    // Update dom by adding new file related data list..
                    appendListItem(response.data, response.is_admin);

                    if (response.has_document_ash_and_ok == true) {
                        if ($('#upload_ash_popup').length > 0) {
                            MicroModal.show("upload_ash_popup");
                        }
                        $('.accordion-menu').find('[data-section="send_postal_validation"]').find('i').removeClass().addClass('fa fa-check control-status-icon fa-fw')
                    }

                    if (response.iframe_validate_popup == true) {
                        $('#iframe-validate-btn-div').removeClass('hidden');
                    }

                    // Hide loading overlay.;
                    hideLoadingOverlay(parentTr);

                    refreshSectionStatus(response.render_reinstruction_popup);

                    return false;
                }
            });

            $(this).off();

            return false;
        });
    });

    function refreshSectionStatus(renderInstructionPopup = false) {
        let errorCount = 0;

        if (slug == "etat-du-dossier" || slug == "etat-du-dossier#") {
            displayLoadingOverlay($('.sections-summary'));
        }

        $.ajax({
            url: Routing.generate('check_ash_section_status', {
                'reference': dossierReference
            }),
            type: 'get',
        }).statusCode({
            500: function (data) {
            },
            400: function (data) {
            },
            200: function (response) {
                $.each(response.data, function (key, value) {
                    let section_slug = value.section_slug;
                    let has_error = value.has_error;
                    let remainingAction = value.remaining_action;

                    if (slug == "pieces-complementaires" || slug == "pieces-complementaires#") {
                        let statusTd = $('.sections-summary').find('[data-td-section="' + section_slug + '"]');

                        if (remainingAction != null) {
                            errorCount++;
                            $('.sections-summary').find('.section-remaining-action').text(remainingAction);
                        } else {
                            $('.sections-summary').find('.section-remaining-action').text("");
                        }

                        if (!has_error) {
                            statusTd.find('i').removeClass().addClass('fa fa-check fa-fw');
                        } else {
                            statusTd.find('i').removeClass().addClass('fa fa-times fa-fw');
                            errorCount++;
                        }

                        hideLoadingOverlay($('.sections-summary'));
                    }
                });

                if (getSubdomain() != "extranet") {
                    if (renderInstructionPopup && errorCount == 0) {
                        renderInstructionPopUp();
                    }
                }


                return false;
            }
        });
    }

    function getSubdomain() {
        let hostName = window.location.hostname;

        var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
        var urlParts = regexParse.exec(hostName);
        return hostName.replace(urlParts[0], '').slice(0, -1);
    }

    function buildCurrentSelectors(uploadTrigger) {
        parentTr = uploadTrigger.closest("td").closest('tr');
        fileTd = parentTr.find('.file-table-data');

        errorsContainer = parentTr.find('.attachment-upload-error');

        actionList = parentTr.find('.action-list-td');
        fileOwnerList = parentTr.find('.file-owner-list');
        acceptedAtList = parentTr.find('.file-accepted-at-list');
        createdAtList = parentTr.find('.file-created-at-list');

        fileItemList = fileTd.find('.file-item-list');

        uploadForm = fileTd.find('form');
        fileInput = fileTd.find('[data-vte-upload]');
        dossierReference = fileTd.find('input:hidden[name*=reference]').val();
        fileCategoryId = fileTd.find('input:hidden[name*=file_category_id]').val();

    }

    function displayLoadingOverlay(element) {
        element.LoadingOverlay("show", {imageColor: "#1b284f"});
    }

    function hideLoadingOverlay(element) {
        element.LoadingOverlay("hide");
    }

    function appendErrors(errors) {
        refreshErrors();

        $.each(errors, function (k, message) {
            errorsContainer.append(message + '</br>');
        });
    }

    function appendFileItem(data) {
        downloadRoute = Routing.generate('core_dossier_file_download', {
            reference: dossierReference,
            dossier_file_id: data.file_id
        });

        link = '<li class="file-item"><a href=' + downloadRoute + '>' + data.original_name + '</a></li>';

        fileItemList.append(link)
    }

    function appendListItem(data, isAdmin) {
        let createdAtItem = '<li class="item">' + data.created_at + '</li>';
        let ownerItem = '<li class="item">' + data.owner + '</li>';

        removeRoute = Routing.generate('core_dossier_file_remove', {
            reference: dossierReference,
            dossier_file_id: data.file_id,
            subdomain: getSubdomain()
        });

        viewRoute = Routing.generate('core_dossier_file_view', {
            reference: dossierReference,
            dossier_file_id: data.file_id,
            subdomain: getSubdomain()
        });

        acceptRoute = Routing.generate('core_dossier_file_validate', {
            reference: dossierReference,
            dossier_file_id: data.file_id,
            subdomain: getSubdomain()
        });

        refuseRoute = Routing.generate('core_dossier_file_refuse', {
            reference: dossierReference,
            dossier_file_id: data.file_id,
            subdomain: getSubdomain()
        });

        let ul;

        if (isAdmin) {
            ul = $("<div class='row'>" +
                "<ul class='list-inline action-list'>" +
                "<li class='action-item'><a class='removeDocument' href='" + removeRoute + "'><i class='fa fa-trash-alt remove-document-icon'></i></a></li>" +
                "<li class='action-item'><a target='_blank' href='" + viewRoute + "'><i class='fa fa-search view-document-icon'></i></a></li>" +
                "<li class='action-item'><a href='" + acceptRoute + "'><i class='fa fa-check'></i></a></li>" +
                "<li class='action-item'><a href='" + refuseRoute + "'><i class='fa fa-ban'></i></a></li>" +
                "</ul>" +
                "</div>");
        } else if ($.inArray(dossier_status, ['preaccepted', 'validation', 'complete', 'waiting-pncee-validation', 'signature-in-progress']) !== -1) {
            ul = $("<div class='row'>" +
                "<ul class='list-inline action-list'>" +
                "<li class='action-item'><a target='_blank' href='" + viewRoute + "'><i class='fa fa-search view-document-icon'></i></a></li>" +
                "</ul>" +
                "</div>");
        } else {
            ul = $("<div class='row'>" +
                "<ul class='list-inline action-list'>" +
                "<li class='action-item'><a class='removeDocument' href='" + removeRoute + "'><i class='fa fa-trash-alt remove-document-icon'></i></a></li>" +
                "<li class='action-item'><a target='_blank' href='" + viewRoute + "'><i class='fa fa-search view-document-icon'></i></a></li>" +
                "</ul>" +
                "</div>");
        }

        ul.appendTo(actionList);

        createdAtList.append(createdAtItem);
        fileOwnerList.append(ownerItem);
    }

    function refreshErrors() {
        errorsContainer.text("");
    }

    function renderInstructionPopUp() {
        $('#ask-for-instruction-popup').data('render-popup', true);
        showInstructionPopup();
    }
});
