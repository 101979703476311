import MicroModal from "micromodal";

$('document').ready(function () {
    if ($("#modal-1").length > 0) {
        MicroModal.show("modal-1");

        var wafList = $("[data-waf-id]");
        var ctrlMessageList = $("[data-ctrl-waf-id]");

        $("#modal-modify-btn").add(".modal__overlay").click(function () {
            $.each(ctrlMessageList, function (k, element) {
                let wafId = $(element).data("ctrl-waf-id");

                wafList.filter(function () {
                    return $(this).data("waf-id") == wafId
                }).css("border", "2px dotted red");

            });

            MicroModal.close("modal-1");
        });
    }
});

