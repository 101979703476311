$('document').ready(function () {

    var devisSigneDateDay = $('#ash_administration_DEVIS_SIGN_DATE_day');
    var devisSigneDateMonth = $('#ash_administration_DEVIS_SIGN_DATE_month');
    var devisSigneDateYear = $('#ash_administration_DEVIS_SIGN_DATE_year');

    var devisSendingDateDay = $('#ash_administration_DEVIS_SENDING_DATE_day');
    var devisSendingDateMonth = $('#ash_administration_DEVIS_SENDING_DATE_month');
    var devisSendingDateYear = $('#ash_administration_DEVIS_SENDING_DATE_year');

    if (devisSigneDateDay.length > 0 & devisSigneDateMonth.length > 0 & devisSigneDateYear.length > 0
        & devisSendingDateDay.length > 0 & devisSendingDateMonth.length > 0 & devisSendingDateYear.length > 0) {
        devisSendingDateDay.change(function () {
            if (devisSigneDateDay.val() == "") {
                devisSigneDateDay.val($(this).val());
            }
        });
        devisSendingDateMonth.change(function () {
            if (devisSigneDateMonth.val() == "") {
                devisSigneDateMonth.val($(this).val());
            }
        });
        devisSendingDateYear.change(function () {
            if (devisSigneDateYear.val() == "") {
                devisSigneDateYear.val($(this).val());
            }
        });
    }

    var subContractorContainer = $("#identite_du_sous_traitant_wrapper");
    var invMeoInput = $('input:radio[name*=INV_PRO_MOA]');

    if (isSubcontracting()) {
        displayContainer(subContractorContainer);
        enableInvMeoInputs();
    } else {
        hideContainer(subContractorContainer);
        disableInvMeoInputs();
    }

    invMeoInput.on('change', function () {
        if ($(this).is(':checked') && $(this).val() == 1) {
            displayContainer(subContractorContainer);
            enableInvMeoInputs();
        } else {
            hideContainer(subContractorContainer);
            disableInvMeoInputs();
        }
    });

    function disableInvMeoInputs() {
        subContractorContainer.find('input').prop( "disabled", true );
    }
    function enableInvMeoInputs() {
        subContractorContainer.find('input').prop( "disabled", false );
    }

    var invEnrInputs = $('input:radio[name*=ASH_ENR]');
    var invEnrComb = $('[name="ash_administration[INV_ENR_COMB]"]');

    if (isElecEnr()) {
        hideContainer(invEnrComb.closest('.col-md-12'));
    } else {
        displayContainer(invEnrComb.closest('.col-md-12'));
    }

    invEnrInputs.on('change', function () {

        if ($(this).is(':checked') && $(this).val() == 2) {
            displayContainer(invEnrComb.closest('.col-md-12'));
        } else {
            hideContainer(invEnrComb.closest('.col-md-12'))
        }
    });

    var cdpEnrInputs = $('input:radio[name="ash_administration[INV_CDP_ENR]"]');
    var cdpEnrComb = $('[name="ash_administration[INV_CDP_ENR_COMB]"]');
    var isCondChaudTertiaire = $('[name="ash_administration[INV_IS_COND_CHAUD_TERTIAIRE]"]');

    if (isCdpElecEnr()) {
        hideContainer(cdpEnrComb.closest('.col-md-12'));
        hideContainer(isCondChaudTertiaire.closest('.col-md-12'));
    } else {
        displayContainer(cdpEnrComb.closest('.col-md-12'));
        displayContainer(isCondChaudTertiaire.closest('.col-md-12'));
    }

    cdpEnrInputs.on('change', function () {
        if ($(this).is(':checked') && $(this).val() == 2) {
            displayContainer(cdpEnrComb.closest('.col-md-12'));
            displayContainer(isCondChaudTertiaire.closest('.col-md-12'));
        } else {
            hideContainer(cdpEnrComb.closest('.col-md-12'));
            hideContainer(isCondChaudTertiaire.closest('.col-md-12'))
        }
    });

    var cdpPoseInput = $('input:radio[name="ash_administration[INVOICE_MCDP1]"]');
    var isCondChaudInput = $('input:hidden[name="ash_administration[INV_IS_COND_CHAUD]"]');

    cdpPoseInput.on('change', function () {
        if ($(this).is(':checked') && $(this).val() == 2) {
            isCondChaudInput.val(0);
        } else {
            isCondChaudInput.val(1);
        }
    });

    /* Start bar-th-163 */
    var $typeOperation = $('[name="ash_administration[INV_OPER_CONDUIT]"]');

    if ($typeOperation.length > 0 && (new Date(QUOTATION_DATE) >= new Date('2021-11-01'))) {
        var $replaceType = $('[name="ash_administration[INV_REPLACE_TYPE]"]').closest('.col-md-12'); // hide if 2
        var $isConduit = $('[name="ash_administration[INV_IS_CONDUIT_SIMULTANEOUS]"]').closest('.col-md-12'); // show if 3
        var $reuseConduit = $('[name="ash_administration[INV_REUSE_CONDUIT_TYPE]"]').closest('.col-md-12'); // show if 3
        var $longConduit = $('[name="ash_administration[INV_LONG_CONDUIT]"]').closest('.col-md-12'); // show if 3

        if ($typeOperation.length > 2) {
            $typeOperation.parent().find('span.radio-btn').css('width', '100%');
        }
        if ($('[name="ash_administration[INV_OPER_CONDUIT]"]:checked').val() == 2) {
            $replaceType.hide();
            $isConduit.hide();
            $reuseConduit.hide();
            $longConduit.show();
        } else if ($('[name="ash_administration[INV_OPER_CONDUIT]"]:checked').val() == 3) {
            $isConduit.show();
            $reuseConduit.show();
            $replaceType.hide();
            $longConduit.hide();
        } else {
            $replaceType.show();
            $isConduit.hide();
            $reuseConduit.hide();
            $longConduit.hide();
        }

        $typeOperation.on('change', function () {
            if ($(this).is(':checked')) {
                if ($(this).val() == 2) { // Fumée individuel
                    $replaceType.hide();
                    $isConduit.hide();
                    $reuseConduit.hide();
                    $longConduit.show();
                } else if ($(this).val() == 3) { // Fumée collectif
                    $isConduit.show();
                    $reuseConduit.show();
                    $replaceType.hide();
                    $longConduit.hide();
                } else {
                    $replaceType.show();
                    $isConduit.hide();
                    $reuseConduit.hide();
                    $longConduit.hide();
                }
            }
        });
    }
    /* End bar-th-163 */

    var p3HasPac = $('input:radio[name="ash_administration[P3_HAS_PAC]"]');
    var totNewPacPowerInput = $('input[name="ash_administration[P3_TOT_NEW_PAC_POWER]"]').closest('.col-lg-6');
    
    if (p3HasPac.length > 0 && $('input:radio[name="ash_administration[P3_HAS_PAC]"]:checked').val() == 1) {
        totNewPacPowerInput.show();
    } else {
        totNewPacPowerInput.hide();
    }
    p3HasPac.on('change', function () {
        if ($(this).is(':checked') && $(this).val() == 1) {
            totNewPacPowerInput.show();
        } else {
            totNewPacPowerInput.hide();
        }
    });

    // bar-th-125
    var $typeInstall = $('[name="ash_administration[INV_TYPE_INSTALL]"]');
    var $efficaciteThermique = $('[name="ash_administration[ASH_EFFICACITE_THERMIQUE]"]').closest('.col-lg-6'); // show if 1
    var $classEnergie = $('[name="ash_administration[ASH_CLASS_ENERGIE]"]').closest('.col-lg-6'); // show if 1
    var $surfaceHbitable = $('[name="ash_administration[ASH_SH]"]').closest('.col-lg-6'); // show if 1
    var $nombreApp = $('[name="ash_administration[ASH_NAPP]"]').closest('.col-lg-6'); //show if 3
    var $rendTemp = $('[name="ash_administration[INV_REND_TEMP]"]').closest('.col-lg-6'); //show if 3
    var $puissance = $('[name="ash_administration[ASH_VMC_PUISSANCE_IND]"]').closest('.col-lg-6'); //show if 1 or 2
    var $rappTemp = $('[name="ash_administration[INV_RAPP_TEMP]"]').closest('.col-lg-6'); //show if 1 or 2
    var $efficacite = $('[name="ash_administration[INV_EFFICACITE]"]').closest('.col-lg-6'); //show if 1 or 2

    if ($typeInstall.length > 0) {
        if ($('[name="ash_administration[INV_TYPE_INSTALL]"]:checked').val() == 3) {
            $nombreApp.show();
            $surfaceHbitable.hide();
            $efficaciteThermique.hide();
            $classEnergie.hide();
            $puissance.hide();
            $rendTemp.show();
            $rappTemp.hide();
            $efficacite.show();
        } else if ($('[name="ash_administration[INV_TYPE_INSTALL]"]:checked').val() == 1 || $('[name="ash_administration[INV_TYPE_INSTALL]"]:checked').val() == 2 ) {
            $surfaceHbitable.show();
            $efficaciteThermique.show();
            $classEnergie.show();
            $nombreApp.hide();
            $puissance.show();
            $rendTemp.hide();
            $rappTemp.show();
            $efficacite.hide();
        } else {
            $surfaceHbitable.hide();
            $efficaciteThermique.hide();
            $classEnergie.hide();
            $nombreApp.hide();
            $puissance.hide();
            $rendTemp.hide();
            $rappTemp.show();
            $efficacite.hide();
        }
    }

    $typeInstall.on('change', function () {
        if ($(this).is(':checked')) {
            if ($(this).val() == 3) {
                $nombreApp.show();
                $surfaceHbitable.hide();
                $efficaciteThermique.hide();
                $classEnergie.hide();
                $puissance.hide();
                $rendTemp.show();
                $rappTemp.hide();
                $efficacite.show();
            } else {
                $surfaceHbitable.show();
                $efficaciteThermique.show();
                $classEnergie.show();
                $nombreApp.hide();
                $puissance.show();
                $rendTemp.hide();
                $rappTemp.show();
                $efficacite.hide();
            }
        }
    });

    // Additional fields for BAR-TH-164
    function toggleTravauxAutreInput($this) {
        if ($this.find('input[type=checkbox]').is(":checked")) {
            $('.travaux-autre').show();
        } else {
            $('.travaux-autre').hide();
        }
    }

    let $travauxAutreInput = $('.ash_choice_wrapper').find('.item.autre')
    let $quelTravauxDF = $('.ash_choice_wrapper').find("input[id$='INV_QUELS_TRAVAUX_7']");//double fenetre
    if (!$quelTravauxDF.is(':checked') && typeof ELIGIBLE_WORK !== 'undefined' && ELIGIBLE_WORK == "BAR-TH-164") {
        $quelTravauxDF.parent().remove();
    }
    if ($travauxAutreInput) {
        toggleTravauxAutreInput($travauxAutreInput);
        $travauxAutreInput.on('change', function () {
            toggleTravauxAutreInput($(this));
        })
    }

    // move and show/hide the fields like ‹‹ AUD_#####_POSTFIX ›› just below the each related work checkbox
    let domainWorkElements = $("[data-postfix-id]");
    domainWorkElements.each(function (e) {
        let fieldRow = $(this);
        let val = $(this).data("postfix-id");
        let wrapperParentRow = $('[data-field-wrapper-parent="'+ val +'"]');
        let wrapperRow = $('[data-field-wrapper="'+ val +'"]');
        if (wrapperParentRow.find("input[id^='ash_administration_INV_QUELS_TRAVAUX']").is(":checked")) {
            fieldRow.show();
        }
        wrapperParentRow.find("input[id^='ash_administration_INV_QUELS_TRAVAUX']").on('change', function() {
            if ($(this).is(":checked")) {
                fieldRow.show();
            } else {
                fieldRow.hide();
            }
        })
        if (wrapperRow.find('[data-postfix-id]').hasClass('col-md-12') || wrapperRow.find('[data-postfix-id]').length >= 2) {
            wrapperRow = $('[data-field-wrapper="'+ val +'_b"]');
        }
        let elm = fieldRow.detach();
        wrapperRow.append(elm);
    })


    // end BAR-TH-164

    // bar-en-102
    if (typeof ELIGIBLE_WORK !== 'undefined' && ELIGIBLE_WORK === "BAR-EN-102") {
        let devisSignDate = $('[name^="ash_administration[DEVIS_SIGN_DATE]"]');
        let devisPrevisitDate = $('[name^="ash_administration[DEVIS_PREVISIT_DATE]"]').closest('.col-lg-6');
        let devisPrevisitDateMention = $('[name^="ash_administration[DEVIS_PREVISIT_DATE_MENTION]"]').closest('.col-md-12');
        let devisPrevisitRecordAttached = $('[name^="ash_administration[DEVIS_PREVISIT_RECORD_ATTACHED]"]').closest('.col-md-12');
        let devisWorkStartDate = $('[name^="ash_administration[DEVIS_WORK_START_DATE]"]').closest('.col-md-12');
        if (devisSignDate.length > 0) {
            previsitDateShowHide();
            devisSignDate.on("input", function () {
                previsitDateShowHide();
            })
        }
        function previsitDateShowHide() {
            // var devisSigneDate = new Date(devisSigneDateYear.val()+'-'+devisSigneDateMonth.val()+'-'+devisSigneDateDay.val()+' 00:00:00');
            let year = parseInt(devisSigneDateYear.val());
            let month = parseInt(devisSigneDateMonth.val());
            let day = parseInt(devisSigneDateDay.val());

            if (year > 2022 || (year == 2022 && month >= 5)) {
                devisPrevisitDate.show();
                devisPrevisitDateMention.show();
                devisPrevisitRecordAttached.show();
                devisWorkStartDate.show();
            } else {
                $('[name="ash_administration[DEVIS_PREVISIT_DATE][day]"]').val('')
                $('[name="ash_administration[DEVIS_PREVISIT_DATE][month]"]').val('')
                $('[name="ash_administration[DEVIS_PREVISIT_DATE][year]"]').val('')
                devisPrevisitDate.hide();
                devisPrevisitDateMention.hide();
                devisPrevisitRecordAttached.hide();
                devisWorkStartDate.hide();
            }
        }
    }
    //end bar-en-102

    if (isCondChaud()) {
        $('input:radio[name="ash_administration[INVOICE_MCDP1]"][value="1"]').prop('checked', true);
    }

    /**
     *
     * @param elementToShow
     */
    function displayContainer(elementToShow) {
        elementToShow.show();
    }

    /**
     *
     * @param elementToHide
     */
    function hideContainer(elementToHide) {
        elementToHide.hide();
    }

    /**
     *
     * @returns {boolean}
     */
    function isSubcontracting() {
        return (invMeoInput.is(':checked') && invMeoInput.prop('checked') == 1);
    }

    /**
     *
     * @returns {*|jQuery|boolean}
     */
    function isElecEnr() {
        return (invEnrInputs.is(':checked') && invEnrInputs.prop('checked') == 1);
    }

    /**
     *
     * @returns {*|jQuery|boolean}
     */
    function isCdpElecEnr() {
        return (cdpEnrInputs.is(':checked') && cdpEnrInputs.prop('checked') == 1);
    }

    function isCondChaud() {
        return (isCondChaudInput.val() == 1);
    }

    var cdpEnrAuditInputs = $('input:radio[name="ash_administration[INV_CDP_ENR_AUDIT]"]');
    var cdpEnrCompAuditInput = $('select[name*="ash_administration[INV_CDP_ENR_COMB_AUDIT]"]');

    if ($('input:radio[name="ash_administration[INV_CDP_ENR_AUDIT]"]:checked').val() == 1) {
        cdpEnrCompAuditInput.find('option:selected').removeAttr('selected');
        hideContainer(cdpEnrCompAuditInput.closest('.col-md-12'))
    }

    cdpEnrAuditInputs.on('change', function (event) {
        if (event.target.defaultValue == 2) {
            displayContainer(cdpEnrCompAuditInput.closest('.col-md-12'));
        } else {
            cdpEnrCompAuditInput.find('option:selected').removeAttr('selected');
            hideContainer(cdpEnrCompAuditInput.closest('.col-md-12'))
        }
    });

    // bar-th-174 175
    var audRenewAirInsuffisant = $('input:radio[name="ash_administration[AUD_RENEW_AIR_INSUFFISANT]"]');
    var audTravauxPreconise = $('input:radio[name="ash_administration[AUD_TRAVAUX_PRECONISES_STEP_1]"]');
    if ($('input:radio[name="ash_administration[AUD_RENEW_AIR_INSUFFISANT]"]:checked').val() != 1) {
        hideContainer(audTravauxPreconise.closest('.col-md-12'))
    }
    audRenewAirInsuffisant.on('change', function (event) {
        if (event.target.defaultValue == 1) {
            displayContainer(audTravauxPreconise.closest('.col-md-12'));
        } else {
            hideContainer(audTravauxPreconise.closest('.col-md-12'))
        }
    });
    //END 174 175

    // reno-globale
    var vmcModelInput = $('input:radio[name="ash_administration[AUD_VMC_MODEL_VMC]"]');
    var vmcTypeInput = $('input:radio[name*="ash_administration[AUD_VMC_TYPE_VMC]"]');
    if ($('input:radio[name="ash_administration[AUD_VMC_MODEL_VMC]"]:checked').val() == 2) {
        vmcTypeInput.find('checked').removeAttr('checked');
        hideContainer(vmcTypeInput.closest('.col-md-12'))
    }
    vmcModelInput.on('change', function (event) {
        if (event.target.defaultValue == 1) {
            displayContainer(vmcTypeInput.closest('.col-md-12'));
        } else {
            vmcTypeInput.find(':checked').removeAttr('checked');
            hideContainer(vmcTypeInput.closest('.col-md-12'))
        }
    });
    //end of reno-globale

    /** <--- beneficiaire coordonnées part **/
    $('#coordonnesBenficiaireUpdateReasonModalTrigger').hide(); // by default we hide the button which open modal
    let currentBeneficiaireCoordonnesValues = []; // array will contain current beneficiaire values
    let newBeneficiaireCoordonnesValues = []; // array will contain new beneficiaire values
    let beneficiaireCoordonnesInputs = [
        $('#ash_administration_ASH_USER_FIRST_NAME'),
        $('#ash_administration_ASH_USER_LAST_NAME'),
        $('#ash_administration_ASH_USER_EMAIL'),
        $('#ash_administration_ASH_USER_SOCIAL_NAME'),
        $('#ash_administration_ASH_USER_SIREN')
    ]; // array containing inputs elements we want to check if values has been updated

    for (let elem in beneficiaireCoordonnesInputs) { // loop over inputs
        currentBeneficiaireCoordonnesValues.push(beneficiaireCoordonnesInputs[elem].val()); // store current values in array
        newBeneficiaireCoordonnesValues.push(beneficiaireCoordonnesInputs[elem].val()); // store current values in array (easy for comparison)

        beneficiaireCoordonnesInputs[elem].donetyping(function(){ // if user stop writing
            newBeneficiaireCoordonnesValues[elem] = beneficiaireCoordonnesInputs[elem].val(); // store new values in newBeneficiaireCoordonnesValues array
            if(JSON.stringify(newBeneficiaireCoordonnesValues) !== JSON.stringify(currentBeneficiaireCoordonnesValues)) { // compare old vs new values
                $('#ash_administration_save').hide(); // hide submit button
                $('#coordonnesBenficiaireUpdateReasonModalTrigger').show(); // show modal button
            } else {
                $('#ash_administration_save').show(); // show submit button
                $('#coordonnesBenficiaireUpdateReasonModalTrigger').hide(); // hide modal button
            }
        }, 5)
    }

    $("#coordonnesBenficiaireUpdateReasonModalTrigger").click(function(event) {
        // open modal if we click on modal trigger button
        event.preventDefault();
        $('#coordonnesBenficiaireUpdateReasonModal').modal('show');
    });

    // when modal is open
    $('#coordonnesBenficiaireUpdateReasonModal').on('shown.bs.modal', function () {
        $('#ash_administration_save_2').prop('disabled', true); // disable the submit button

        // enable submit button only if at least one checbkox has been checked
        $('#coordonnesBenficiaireUpdateReasonModal input[name="ash_administration[confirmUpdateDossierCoordonnees][motif][]"]').change(function(event){
            if ($('#coordonnesBenficiaireUpdateReasonModal input[name="ash_administration[confirmUpdateDossierCoordonnees][motif][]"]:checked').length > 0) {
                // activate validate button
                $('#ash_administration_save_2').prop('disabled', false);
            } else {
                // deactivate validate button
                $('#ash_administration_save_2').prop('disabled', true);
            }
        });
    })
    /** beneficiaire coordonnées part ---> **/

    /* BAR-TH-137 */
    if ($("#ash_administration_HEAT_NETWORK_CONNECTION_1").length) {
        if ($("#ash_administration_HEAT_NETWORK_CONNECTION_1:checked").length) {
            $("#ash_administration_IS_CONNECTION_CERTIFIED_1").closest("div.col-lg-12").show();
        } else {
            $("#ash_administration_IS_CONNECTION_CERTIFIED_1").closest("div.col-lg-12").hide();
        }
        $("input[name='ash_administration[HEAT_NETWORK_CONNECTION]']").on('click', function() {
            if ($(this).val() == 1) {
                $("#ash_administration_IS_CONNECTION_CERTIFIED_1").closest("div.col-lg-12").show();
            } else {
                $("#ash_administration_IS_CONNECTION_CERTIFIED_1").closest("div.col-lg-12").hide();
            }
        });
    }

    /* BAT-TH-127 */
    if ($("input[name='ash_administration[HEAT_NETWORK_CONNECTION]']").length) {
        if ($("input[name='ash_administration[HEAT_NETWORK_CONNECTION]'] option:selected").val() != 1) {
            $("#ash_administration_INV_IS_COND_CHAUD_TERTIAIRE_1").closest("div.col-lg-12").hide();
        }
        $("input[name='ash_administration[HEAT_NETWORK_CONNECTION]']").on("click", function () {
            if ($(this).val() == 1) {
                $("#ash_administration_INV_IS_COND_CHAUD_TERTIAIRE_1").closest("div.col-lg-12").show();
            } else {
                $("#ash_administration_INV_IS_COND_CHAUD_TERTIAIRE_1").closest("div.col-lg-12").hide();
            }
        });
    }

    /* BAT-TH-116 */
    if ($("input[name='ash_administration[OPERATION_TYPE]']").length) {
        if ($("#ash_administration_OPERATION_TYPE_1:checked").length) {
            $("#ash_administration_OLD_STUFF_CLASS_C_1").closest("div.col-md-12").show();
        } else {
            $("#ash_administration_OLD_STUFF_CLASS_C_1").closest("div.col-md-12").hide();
        }
        $("input[name='ash_administration[OPERATION_TYPE]']").on('click', function() {
            if ($(this).val() == 1) {
                $("#ash_administration_OLD_STUFF_CLASS_C_1").closest("div.col-md-12").show();
            } else {
                $("#ash_administration_OLD_STUFF_CLASS_C_1").closest("div.col-md-12").hide();
            }
        });
    }

    /* BAR-TH-173 */
    if ($('select[name="ash_administration[INV_SYSTEM_REGUL]"]').length) {
        var invSystemRegul = $('select[name="ash_administration[INV_SYSTEM_REGUL]"]');
        var invOldSystem = $('select[name="ash_administration[INV_OLD_SYSTEM]"]');
        invSystemRegul.on('change', function (event) {
            if ($(this).val() == 2) {
                invOldSystem.closest('.col-md-12').show();
            }
            else {
                invOldSystem.closest('.col-md-12').hide();
            }
        }).change();
    }
});
