export function getSubdomain() {
    let hostName = window.location.hostname;

    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostName);
    return hostName.replace(urlParts[0], '').slice(0, -1);
}

export function getSlug() {
    return window.location.href.split('/').pop();
}