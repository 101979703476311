import MicroModal from "micromodal";
import {showInstructionPopup} from "./popups";

var slug = window.location.href.split('/').pop();


var dossier_status = $('input:hidden[name*=dossier_status]').val();

var len = slug.indexOf("?");
if (len > 0) {
    slug = slug.substring(slug, len);
}

if ($.inArray(slug, ["facture", "devis", "revenus", "coordonnees", "l-attestation-de-mon-client", "j-envoi-mon-attestation",
    "certification-rge", 'rib', 'facture-audit', 'devis-audit', 'audit-energetique', 'controle-cofrac', 'audit-reno-globale',
    "devis-reno-globale", "facture-reno-globale", "pro-reno-globale"
]) > -1) {
    $('document').ready(function () {
        var fileInput = $('[data-vte-upload]'),
            uploadBtn = $('[data-vte-upload-btn]'),
            uploadContainer = $('.upload-container'),
            uploadForm = $('#upload-form-container').find('form'),
            fileCategoryId = $('input:hidden[name*=file_category_id ]').val(),
            dossierReference = $('input:hidden[name*=reference]').val(),
            uploadBox = $('#upload-box'),
            slug = $('div#slug').attr('data-slug');

        var uploadTrigger = $('.upload-trigger');

        uploadTrigger.on('click', function () {
            fileInput.click();
        });

        fileInput.on('change', function () {
            var fileName = $(this).val().split('\\').pop();
            removeFileNameHelper();
            appendFileNameHelper(fileName);
            if(fileName.length > 0) {
                uploadForm.submit();
            }
        });

        uploadForm.on('submit', function (e) {
            e.preventDefault();

            displayLoadingOverlay(uploadContainer);

            refreshBox();

            $.ajax({
                url: Routing.generate('api_dossier_file_upload', {
                    'file_category_id': fileCategoryId,
                    'reference': dossierReference
                }),
                type: 'post',
                data: new FormData(uploadForm[0]),
                cache: false,
                contentType: false,
                processData: false
            }).statusCode({
                400: function (data) {
                    appendErrors(data.responseJSON.errors);
                    hideLoadingOverlay(uploadContainer);
                },
                200: function (response) {
                    let route = Routing.generate('core_dossier_file_pdf_response', {
                        'dossier_file_id': response.data.file_id,
                        'reference': response.data.dossier_reference,
                        'subdomain': getSubdomain()
                    });

                    let removeRoute = Routing.generate('core_dossier_file_remove', {
                        'dossier_file_id': response.data.file_id,
                        'reference': response.data.dossier_reference,
                        'subdomain': getSubdomain()
                    });


                    var doc = '<div class="col-md-12" id="document-viewer">' +
                        '    <div id="pdf-container">' +
                        '        <object type="application/pdf" style="min-height:850px;" class="col-md-12 col-sm-12 col-xs-12"' +
                        '                data="' + route + '"></object>' +
                        '    </div>' +
                        '    <a href="' + removeRoute + '"class="viewer-remove-doc"><i\n' +
                        '                title="Supprimer le document" class="fa fa-trash-alt pull-right"></i></a>'
                    '</div>';

                    if ($.inArray(dossier_status, ['preaccepted', 'validation', 'complete', 'waiting-pncee-validation', 'signature-in-progress']) !== -1) {
                        doc = '<div class="col-md-12" id="document-viewer">' +
                            '    <div id="pdf-container">' +
                            '        <object type="application/pdf" style="min-height:850px;" class="col-md-12 col-sm-12 col-xs-12"' +
                            '                data="' + route + '"></object>' +
                            '    </div>' +
                            '</div>';
                    }


                    if (response.has_document_ash_and_ok == true) {
                        if ($('#upload_ash_popup').length > 0) {
                            MicroModal.show("upload_ash_popup");
                        }
                        $('.accordion-menu').find('[data-section="send_postal_validation"]').find('i').removeClass().addClass('fa fa-check control-status-icon fa-fw')
                    }
                    if (slug == 'controle-cofrac') {
                        location.reload();
                    } else {
                        uploadBox.replaceWith(doc);
                        hideLoadingOverlay(uploadContainer);
                        refreshSectionStatus(response.render_reinstruction_popup);
                    }

                }
            });
        });

        function refreshSectionStatus(renderInstructionPopup = false) {
            let errorCount = 0;

            $.ajax({
                url: Routing.generate('check_ash_section_status', {
                    'reference': dossierReference
                }),
                type: 'get',
            }).statusCode({
                500: function (data) {
                },
                400: function (data) {
                },
                200: function (response) {
                    $.each(response.data, function (key, value) {
                        let section_slug = value.section_slug;
                        let has_error = value.has_error;
                        let remainingAction = value.remaining_action;

                        let statusLink = $('.accordion-menu').find('[data-section="' + section_slug + '"]');

                        if (!has_error && remainingAction == null) {
                            statusLink.find('i').removeClass().addClass('fa fa-check control-status-icon fa-fw')
                        } else {
                            errorCount++;
                            statusLink.find('i').removeClass().addClass('fa fa-times control-status-icon fa-fw')
                        }
                    });

                    if (getSubdomain() != "extranet") {
                        if (renderInstructionPopup && errorCount == 0) {
                            renderInstructionPopUp();
                        }
                    }


                    return false;
                }
            });
        }

        function displayLoadingOverlay(element) {
            element.LoadingOverlay("show", {imageColor: "#1b284f"});
        }

        function hideLoadingOverlay(element) {
            element.LoadingOverlay("hide");
        }

        function removeFileNameHelper() {
            uploadContainer.find('.ash_document_helper').remove();
        }

        function refreshBox() {
            uploadContainer.find('.ash_document_error').remove();
            removeFileNameHelper();
        }

        function appendFileNameHelper(fileName) {
            uploadContainer.append('<p class="ash_document_helper">' + fileName + '</p>');
        }

        function displayButton() {
            uploadBtn.removeClass('hidden')
        }

        function appendErrors(errors) {
            $.each(errors, function (k, message) {
                uploadContainer.append('<p class="ash_document_error">' + message + '</p>');
            });
        }

        function renderInstructionPopUp() {
            $('#ask-for-instruction-popup').data('render-popup', true);
            showInstructionPopup();
        }

        function getSubdomain() {
            let hostName = window.location.hostname;

            var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
            var urlParts = regexParse.exec(hostName);
            return hostName.replace(urlParts[0], '').slice(0, -1);
        }
    });
}