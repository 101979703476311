const ContribVisualiser = require("./../../Core/Simulator/contrib_prevusualize");

iframeSimulatorMgr = function (form,categoryContainer, eligibleContainer, catInfos) {
    var pthis = this;
    this.form = form;
    this.categoryContainer = categoryContainer;
    this.category = this.categoryContainer;
    this.currentCategoryValue = this.category.find('select').val();

    this.eligibleContainer = eligibleContainer;
    this.eligible = this.eligibleContainer.find('select');
    this.currentEligibleValue = this.eligible.val();

    this.workInputs = this.form.find('.workInput');

    this.inputsToHide = [];
    this.worksToHide = [];
    this.worksIds = [];

    this.hideWorkConditions = [
        {
            'triggerName': 'P3_ETAS',
            'cond': function(val) {
                if(!val)
                    return;

                var typePac = pthis.form.find("input[name*=P3_TYPE_PAC]:checked");
                var alertMsg = null;
                var returnresult = false;
                if(val.length > 0){
                    if(typePac.val() == 0){
                        alertMsg = "Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées (Efficacité énergétique saisonnière doit être supérieure ou égale à 126)";
                        returnresult = val < 126;
                    }
                    else if(typePac.val() == 1){
                        alertMsg = "Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées (Efficacité énergétique saisonnière doit être supérieure ou égale à 111)";
                        returnresult = val < 111;
                    }
                }
                if(alertMsg && returnresult){
                    alert(alertMsg);
                    $('#P3_ETAS').find('input').val('');
                    typePac.removeAttr('checked');
                }

                return returnresult;
            },
            'hideList': [111]
        }
    ];

    this.hideInputsConditions = [
        {
            cond: function () {
                if ($.inArray(parseInt(pthis.eligible.val()), [322]) === -1)
                    return false;
                return true;
            },
            hideList: ['RRC_ERR']
        },
        {
            cond: function () {
                if ($.inArray(parseInt(pthis.eligible.val()), [115, 116, 227]) === -1)
                    return false;

                var LOG = pthis.form.find("input[name*=LOG]:checked");
                var logVal = LOG.length > 0 ? LOG.val() : null;
                return logVal == 1;
            },
            hideList: ['NAPP', 'TCH']
        },
        {
            cond: function () {
                if (pthis.eligible.val() != 204)
                    return false;

                var LOG = pthis.form.find("input[name*=LOG]:checked");
                var logVal = LOG.length > 0 ? LOG.val() : null;
                return logVal == 1;
            },
            hideList: ['NAPP']
        },
        {
            cond: function () {
                if ($.inArray(parseInt(pthis.eligible.val()), [111]) === -1)
                    return false;
                return true;
            },
            hideList: ["P3_COPE", "P3_ETAS"]
        }
    ];

    this.category.change(function () {
        pthis.worksIds = [];
        pthis.currentEligibleValue = pthis.eligible.val();
        categoryId = $(this).find('select').val();
        if (pthis.currentCategoryValue != categoryId) {
            pthis.category.find('select').val(categoryId);
            pthis.currentCategoryValue = categoryId;
        }
        pthis.eligible.empty();
        $.each(catInfos[categoryId]['works'], function (key, value) {
            if ($.inArray(parseInt(key), pthis.worksToHide) == -1) {
                var option = $('<option>').attr('value', key).text(value.title);
                pthis.worksIds.push(parseInt(key));
                pthis.eligible.append(option);
            }
        });
        pthis.eligible.change();
        pthis.eligible.val(pthis.currentEligibleValue);
        if (pthis.eligible.val() == null) {
            pthis.eligible.val(pthis.eligible.find("option").first().attr('value'));
        }
    });

    this.applyInputFilters = function () {
        pthis.inputsToHide = [];
        for (var i = 0; i < pthis.hideInputsConditions.length; i++) {
            var conf = pthis.hideInputsConditions[i];
            var doHide = conf.cond();
            if (doHide)
                pthis.inputsToHide = pthis.inputsToHide.concat(conf.hideList);
        }
    };

    this.refreshInputs = function () {
        pthis.workInputs.addClass('no-visibility');
        pthis.workInputs.closest('.col-sm-12').addClass('no-visibility');
        pthis.toggleEnrQuestions();
        pthis.toggleEnrCdpQuestions();
        var workId = pthis.eligibleContainer.find('select:visible').val();

        if (!workId)
            return;

        inputList = catInfos[pthis.currentCategoryValue]['works'][workId].input_list;

        if (!inputList || inputList === 'undefined')
            inputList = [];

        for (var i = 0; i < inputList.length; i++) {
            if ($.inArray(inputList[i], pthis.inputsToHide) == -1) {
                $('#' + inputList[i]).removeClass('no-visibility');
                $('#' + inputList[i]).closest('.col-sm-12').removeClass('no-visibility');
            }
        }
    }

    this.applyWorkFilters = function () {
        pthis.worksToHide = [];
        for (var i = 0; i < pthis.hideWorkConditions.length; i++) {
            var conf = pthis.hideWorkConditions[i];
            if (!conf)
                continue;

            var triggerName = conf.triggerName;
            var condHandler = conf.cond;

            var trigger = pthis.form.find("[name*='" + triggerName + "']");

            // On prend le type de trigger en considération
            var type = trigger.attr('type');
            if (type && type.toLowerCase() == 'radio')
                value = trigger.filter(":checked").val();
            else
                value = trigger.val();

            // Vérifions si la condition est remplie => si elle l'est on devra masquer tous les éléments listés
            var doHide = condHandler(value);

            if (doHide)
                pthis.worksToHide = pthis.worksToHide.concat(conf.hideList);
        }
    };

    this.refreshWorks = function () {
        if(pthis.worksToHide.length > 0 ) {
            var changed = false;
            for (var i = 0; i < pthis.worksToHide.length; i++) {
                if ($.inArray(pthis.worksToHide[i], pthis.worksIds) != -1) {
                    changed = true;
                    pthis.eligible.find('option[value=' + pthis.worksToHide[i] + ']').remove();
                }
            }
            if(changed && pthis.worksIds.length < (pthis.worksToHide.length + pthis.eligible.find('option').length)) {
                pthis.eligible.change();
            }
        }
        else {
            if(pthis.worksIds.length > pthis.eligible.find('option').length) {
                pthis.category.change();
            }
        }
    }

    this.toggleEnrQuestions = function () {
        if ($.inArray(parseInt(pthis.eligible.val()), [111, 112, 113, 114, 227, 307, 306,317]) === -1) {
            $('#ENR').show();
            var enr = $('#ENR').find('input:checked').val();
            if (enr == 1) {
                $('#ENR_COMB, #fz_concurrent').hide().each(function () {
                    $('#fz_concurrent').find('select').val('');
                });
            }
            else if (enr == 2) {
                $('#ENR_COMB').show();
            }

            var enrComb = $('#ENR_COMB select').val();
            if (enrComb == 2) {
                $('#fz_concurrent').show();
            }
            else {
                $('#fz_concurrent').hide().find('select').val('');
            }
        }
        else {
            $('#ENR').hide();
            $('#ENR_COMB').hide();
        }
    };

    this.toggleEnrCdpQuestions = function () {
        if (!$('#CDP_ENR').find('input:checked').val() || $('#CDP_ENR').find('input:checked').val() == 1) {
            $('#CDP_ENR_COMB').closest(".col-sm-12").hide();
        } else {
            $('#CDP_ENR_COMB').closest(".col-sm-12").show();
        }
    };

    this.updateform = function () {
        pthis.applyWorkFilters();
        pthis.refreshWorks();

        pthis.applyInputFilters();
        pthis.refreshInputs();
    }

    // A l'instanciation, on applique les filtres une première fois
    this.category.change();
    this.updateform();
    this.form.find('select, input[type=radio], input[type=text]').change(pthis.updateform);

    // Au post du formulaire, on ignore tous les champs invisibles
    this.form.submit(function (e) {
        var inputs = pthis.workInputs.find('input:not(:visible), select:not(:visible)');
        inputs.each(function() {
            var s = $(this).attr('name');

            var pattern = /\[(.*?)\]/g;
            var match;
            while ((match = pattern.exec(s)) != null)
            {
                if(!inputList.includes(match[1])) {
                    $(this).attr('disabled', 'disabled');
                }
            }
        });
    });
};

$(document).ready(function() {
    // On rend les images des catégories cliquables
    $('#category').find('ul').find('li').click(function () {
        $(this).find('input[type=radio]').click().change();
    });

    $('#category').find('ul').find('li input[type=radio]').change(function () {
        if($(this).is(':checked')) {
            $('#category').find('ul li').removeClass('checked');
            $(this).closest('li').addClass('checked');
        }
        else {
            $(this).closest('li').removeClass('checked');
        }
    });

    $('.iframe-visualise-cdp-contrib-pdf').on('click', function () {
        let $form = $('form[name=finalize_iframe_simulation]');
        let urlParams = new URLSearchParams(window.location.search);
        let $mode = urlParams.get('mode');
        if (!$mode) { $mode = 'register'; }
        let itemId = $(this).data('item-id');
        ContribVisualiser.visualiseCdpContribPdf($form, 'iframe_visualise_cdp_contrib_pdf', itemId, SLUG, $mode);
    });

});