$('document').ready(function () {

    var invProInput = $('input:text[name*=INV_SIRET]');
    var subcontractorInput = $('input:text[name*=INV_SUBCONTRACTOR_SIRET]');
    var dossierRef = $('input:hidden[name*=dossier_ref]');
    var authUser = $('input:hidden[name*=authenticated_user]');
    var siret;
    var devisSignDate = $('[name*="[DEVIS_SIGN_DATE]"]')


    $("#generate-insee-avis-btn").on('click', (event) => {
        event.preventDefault();
        uploadAndAttachInseeDoc();
    })

    devisSignDate.on('input', function () {
        var devisSignDateDay = $('[name*="[DEVIS_SIGN_DATE][day]"]');
        var devisSignDateMonth = $('[name*="[DEVIS_SIGN_DATE][month]"]');
        var devisSignDateYear = $('[name*="[DEVIS_SIGN_DATE][year]"]');

        if (
            devisSignDate.length > 0 &
            (devisSignDateDay.val() != "" & devisSignDateDay.val().length == 2) &
            (devisSignDateMonth.val() != "" & devisSignDateMonth.val().length == 2) &
            (devisSignDateYear.val() != "" & devisSignDateYear.val().length == 4)
        ) {
            $.ajax(Routing.generate('api_partner_has_rge_certificat', {
                reference: dossierRef.val(),
                auth_id: authUser.val(),
                devisSignDate: devisSignDate.val(),
                devisSignDateMonth: devisSignDateMonth.val(),
                devisSignDateYear: devisSignDateYear.val()
            }), {}).statusCode({
                400: function (data) {
                    return false;
                },
                200: function (data) {
                    if(data.partner_document_id != undefined) {
                    $.ajax(Routing.generate('api_partner_attach_rge_certificat', {
                        reference: dossierRef.val(),
                        auth_id: authUser.val(),
                        partner_document_id: data.partner_document_id,
                    }), {}).statusCode({
                        400: function (data) {
                            return false;
                        },
                        200: function (data) {
                            $('.accordion-menu').find('[data-section="certification-rge"]').find('i').removeClass().addClass('fa fa-check control-status-icon fa-fw');
                            return true;
                        }
                    });
                    }
                    else {
                        return false;
                    }
                }
            });
        }
    });


    function showAlertBox(title, message) {
        $.alert({
            title: title,
            content: message
        });
    }

    function showPreFillConfirmBox(data, type, withDocument = true, message = "Pré-remplir les informations") {
        $.confirm({
            title: message,
            content: data.message,
            type: 'red',
            buttons: {
                ok: {
                    text: "Confirmer",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        if (type == 'inv-subcontractor') {
                            fillAshArtisanData(data.pro_data)
                        } else if (type == 'ash-pro') {
                            fillAshProData(data.pro_data);
                        } else if (type == 'inv-pro') {
                            fillInvoiceProData(data.pro_data)
                        }
                        //uploadAndAttachInseeDoc();

                        uploadAndAttachRgeCertificat();
                    }
                },
                cancel: {
                    text: "Annuler",
                    action: function () {
                        return;
                    }
                }
            }
        });
    }

    function showAlertBoxRefuseDossier(title, message) {
        $.alert({
            title: title,
            content: message,
            type: 'red',
        });
        $.ajax(Routing.generate('dossier_refused', {reference: dossierRef.val()}));
    }

    // EVENTS
    invProInput.on('input', function () {
        if ($(this).val().length == 14) {
            siret = $(this).val();
            $.ajax(Routing.generate('get_professional_siren_block'))
            .statusCode({
                200: function (data) {
                    var sirenBlock = data;
                    var siren = siret.substr(0, 9);
                    if ($.inArray(siren, sirenBlock) > -1) {
                        showAlertBoxRefuseDossier("Ce professionnel n’est pas éligible", "");
                    }
                    else {
                        getProData(siret, 'inv-pro');
                    }
                }});
        }
    });

    subcontractorInput.on('input', function () {
        if ($(this).val().length == 14) {
            siret = $(this).val();
            getProData(siret, 'inv-subcontractor');
        }
    });

    function uploadAndAttachInseeDoc() {
        $('#insee-avis-loader').toggle();
        $('#generate-insee-avis-btn').toggle();
        $.ajax({
            url: Routing.generate('insee_avis_situation'),
            data: {
                siret: invProInput.val(),
                reference: dossierRef.val(),
            },
            method: 'POST',
            dataType: 'json',
            success(data, status, xhr) {
                location.reload();
            },
            error(xhr, status, error) {
                console.log(xhr);
                alert(error + ' ' + status + ' : ' + xhr.responseJSON.message);
                $('#insee-avis-loader').toggle();
                $('#generate-insee-avis-btn').toggle();
            }
        });

        return false;
    }

    // Async call to upload & attach RGE document to dossier (CERTIFICAT RGE)
    function uploadAndAttachRgeCertificat() {
        $.ajax(Routing.generate('api_artisan_upload_attach_rge_certificat', {
            siret: siret,
            reference: dossierRef.val(),
            auth_id: authUser.val()
        })).statusCode({
            400: function (data) {
                return false;
            },
            200: function (data) {
            }
        });
    }

    // FETCH DATA FROM OPENDATASOFT & BFM API
    function getProData(siret, type, prefillMissingInputs = null, withDocument = false) {
        $.ajax(Routing.generate('opendatasoft_get_pro_data', {
            siret: siret,
            prefill_missing_inputs: prefillMissingInputs
        })).statusCode({
            400: function (data) {
                showAlertBox("Un problème est survenu", data.responseJSON.message)
            },
            200: function (data) {
                showPreFillConfirmBox(data, type, withDocument);
            }
        })
    }

    // FILL METHODS //
    /**
     * @param data
     */
    function fillInvoiceProData(data) {
        $('input:text[name*=INV_PRO_FIRSTNAME]').val(data.firstname);
        $('input:text[name*=INV_PRO_LASTNAME]').val(data.lastname);
        $('input:text[name*=INV_PRO_FUNCTION]').val(data.job);
        $('input:text[name*=INV_SOCIAL_NAME]').val(data.social_name);
        $('input:text[name*=INV_ADRESSE_PRO]').val(data.address);
        $('input:text[name*=INV_ZIPCODE_PRO]').val(data.zip_code);
        $('input:text[name*=INV_CITY_PRO]').val(data.city);

        if (data.landline) {
            $('input:text[name*=INV_PRO_TELEPHONE]').val(data.landline);
        }

        if (data.mobile) {
            $('input:text[name*=INV_PRO_MOBILE]').val(data.mobile);
        }
    }

    /**
     * @param data
     */
    function fillAshProData(data) {
        $('input:text[name*=ASH_PRO_LAST_NAME]').val(data.lastname);
        $('input:text[name*=ASH_PRO_FIRST_NAME]').val(data.firstname);
        $('input:text[name*=ASH_PRO_SOCIAL_NAME]').val(data.social_name);
        $('input:text[name*=ASH_PRO_ADDRESS]').val(data.address);
        $('input:text[name*=ASH_PRO_ZIPCODE]').val(data.zip_code);
        $('input:text[name*=ASH_PRO_CITY]').val(data.city);
        $('input:text[name*=ASH_PRO_FUNCTION]').val(data.job)
    }

    /**
     * @param data
     */
    function fillAshArtisanData(data) {
        $('input:text[name*=INV_SUBCONTRACTOR_LAST_NAME]').val(data.lastname);
        $('input:text[name*=INV_SUBCONTRACTOR_FIRST_NAME]').val(data.firstname);
        $('input:text[name*=INV_SUBCONTRACTOR_SOCIAL_NAME]').val(data.social_name);
    }
});