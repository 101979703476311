$(document).ready(function () {
    var form;

    $('.save-proof-mode').click(function () {
        id = $(this).closest('tr').attr('data-control-id');
        textArea = $("textarea[data-control='" + id + "']");

        if (textArea.val().length > 0) {
            form = $(this).closest('tr').find('form');

            if (confirm("Êtes-vous sûr de vouloir sauvegarder ce message ?")) {
                form.submit();
            }
        }
        return false;
    });

    $('.control-checkbox').click(function (e) {
        if ($(this).is(':checked')) {
            markAsSolved($(this).attr('data-dossier-reference'), $(this).attr('data-control-id'), $(this).closest('tr'))
        } else {
            markAsUnsolved($(this).attr('data-dossier-reference'), $(this).attr('data-control-id'), $(this).closest('tr'))
        }
    });

    function markAsSolved(reference, control_id, selector) {
        $.ajax(Routing.generate('extranet_dossier_control_mark_as_solved', {
            reference: reference,
            id: control_id
        })).statusCode({
            400: function (data) {
                alert('Un problème est survenu.');
            },
            200: function (data) {
                selector.toggleClass('resolvedProblem')
            }
        });
    }


    function markAsUnsolved(reference, control_id, selector) {
        $.ajax(Routing.generate('extranet_dossier_control_mark_as_unsolved', {
            reference: reference,
            id: control_id
        })).statusCode({
            400: function (data) {
                alert('Un problème est survenu.');
            },
            200: function (data) {
                selector.removeClass('resolvedProblem')
            }
        });
    }
});