$('document').ready(function () {

    /**
     *
     * @param elementToShow
     */
    function displayContainer(elementToShow) {
        elementToShow.show();
    }

    /**
     *
     * @param elementToHide
     */
    function hideContainer(elementToHide) {
        elementToHide.hide();
    }

    var proFields = $('#is_entreprise_fields').hide();

    var isEntrepriseField = $('input:radio[name*=is_entreprise]');
    var checked = $('input:radio[name*=is_entreprise]:checked');

    if (checked.val() > 0) {
        proFields.show();
    }

    isEntrepriseField.on('change', function () {
        if ($(this).val() == 1) {
            proFields.show()
        } else {
            proFields.hide();
        }
    });

    var cdpEnrAuditInputs = $('input:radio[name="ash_administration[INV_CDP_ENR_AUDIT]"]');

    cdpEnrAuditInputs.on('change', function (event) {
        if (event.target.defaultValue == 2) {
            displayContainer($('select[name*="ash_administration[INV_CDP_ENR_COMB_AUDIT]"]').closest('.col-md-12'));
        } else {
            hideContainer($('select[name*="ash_administration[INV_CDP_ENR_COMB_AUDIT]"]').closest('.col-md-12'))
        }
    });

    $('[name*="[FIRST_DOSSIER_REF]"]').parents('.field-container').addClass('hidden');
    $('[name*="[CLASS_ENR_STEP1]"]').parents('.field-container').addClass('hidden');
    $('[name*="[NB_ETAPE"]').on('change', function (event) {
        if ($(this).val() == 1) {
            $('[name*="[FIRST_DOSSIER_REF]"]').parents('.field-container').addClass('hidden');
            $('[name*="[CLASS_ENR_STEP1]"]').parents('.field-container').addClass('hidden');
        } else {
            $('[name*="[FIRST_DOSSIER_REF]"]').parents('.field-container').removeClass('hidden');
            $('[name*="[CLASS_ENR_STEP1]"]').parents('.field-container').removeClass('hidden');
        }
    });
});
