$('document').ready(function () {

    var parentAutocomplete = $('input:text[name*=parent_autocomplete]');
    var parentInput = $('input:hidden[id*=parent_autocomplete]').add($('input:hidden[id*=parent]'));

    parentAutocomplete.autocomplete({
        source: function (request, response) {
            $.ajax(Routing.generate('api_partner_find_all', {
                query: parentAutocomplete.val()
            })).done(function (data) {
                response($.map(data, function (parent) {
                    return {
                        value: parent.companyName,
                        id: parent.id
                    }
                }));
            })
        },
        minLength: 3,
        select: function (e, data) {
            parentInput.val(data.item.id);
        }
    });
});